// --------------------
// BUTTON STYLE
//---------------------

.btn,
button,
input[type=submit],
input[type=button] {
    background: $red1 ;
    color: #fff;
    font-family: 'BarlowCondensed', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 4.8rem;
    letter-spacing: -0.02em;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border: 0;
    border-radius: 10px;
    width: 100%;
    max-width: 800px;
    padding: 13px 10px 15px;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12px;
    line-height: .9;
    text-transform: uppercase;
    white-space: normal;
    transition: transform 300ms ease;
    outline: none;
    border: none;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
        background: $red1;
        color: #fff;
        box-shadow: none !important;
        outline: none;
        border:none;
        transform: scale(1.02);
    }

    span{
        display: block;
        margin: 8px 0 0;
    }

    i,span{
        font-style: normal;
        font-weight: 400;
        font-size: 1.3rem;
        line-height: .95;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: $red2;
        font-family: $body-font;
        text-shadow: none;
        pointer-events:none;
    }

    .modal &{
        padding: 12px 10px 16px;
    }

    .section-hero &{
        @include media-breakpoint-up(sm){
            padding: 17px 10px 21px;

            i,span{
                font-size: 1.5rem;
            }
        }
    }

    @include media-breakpoint-down(sm){
        padding: 18px 15px 19px;
        font-size: 4.1rem;
        
        i,span{
            font-size: 1.4rem;
            line-height: 1.1;
            letter-spacing: 0.01em;
        }
    }
}

.btn-section {
    position: relative;
    z-index: 5;
    
    p {
        width: 90%;
        margin: 0 auto;
    }
}