footer {
	background: #FFF;
	padding: 35px 0;

	p{
		font-size: 13px;
		text-align: center;
		color: #000000;
		max-width: 1140px;
		margin: 0 auto 2rem;

		a{
			color: inherit;
			text-decoration: none;
		}
	}
}