.expired{
	.section-hero{
		min-height: 100vh;
	}

	h2{
		color:#FFF;
		font-size: 8rem;
		max-width: 700px;
		margin: 0 auto 3rem;
		text-shadow: 0 4px 4px rgba(0,0,0,.2);

		@include media-breakpoint-up(lg){
			font-size: 11rem;
		}
	}

	p{
		color:#FFF;
		margin: 0 0 3rem;
		font-size: 2.2rem;
	}

	.thankyou{
		display: none;
	}

	form{
		max-width: 500px;
		margin: 4rem auto 0;

		@include media-breakpoint-up(lg){
			margin: 5rem auto 0;
		}
	}

	&:not(.email-gate__open)  {
		.thankyou{
			display: block;
		}
		.not-thankyou{
			display: none;
		}
	}
}