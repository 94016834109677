.email-gate {
    display: none;
}

body.email-gate__open:not(.expired) {
    overflow: hidden;

    .email-gate {
        display: block;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        z-index: 9;
        background: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(40px);
        padding-left: 10px;
        padding-right: 10px;

        h3 {
            margin-bottom: 20px;
            max-width: 470px;
            margin-left: auto;
            margin-right: auto;
            font-size: 4rem;

            @include media-breakpoint-down(md) {
                font-size: 4rem;
            }
        }

        form {
            background: rgba(0, 0, 0, .3);
            border-radius: 15px;
            margin: 20vh auto 0;
            max-width: 640px;
            padding: 30px;
            text-align: center;
            width: 100%;

            input,
            button {
                max-width: 430px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}