form {
    input[type=text],
    input[type=email],
    input[type=tel],
    textarea,
    select {
        width: 100%;
        padding: 0px 20px;
        box-shadow: 1px 1px 10px rgba(0,0,0,0.1);
        border: none;
        margin:0 0 10px;
        border-radius: 5px;
        font-size: 2rem;
        line-height: 1;
        height: 50px;

        @include media-breakpoint-up(md) {
            padding: 0px 20px;
            height: 65px;
        }

        &:-internal-autofill-selected {
            background-color: $white !important;
            background-image: none !important;
            color: -internal-light-dark-color(black, white) !important;
        }
    }

    textarea {
        height: 210px !important;
    }

    select {
        cursor: pointer;
        appearance: none;
        background: #FFF url('../images/arrow-down.png') no-repeat 96% 50%/16px 10px;
    }

    .slc-row{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .slc-col{
        width:100%;

        @include media-breakpoint-up(sm) {
            width: calc(50% - 5px);
        }
    }

    .iti{
        margin: 0 0 10px;
    }

    label.error,
    .field-error {
        display: block;
        margin-bottom: 10px;
        margin-top: 0px;
        color: red;
        font-size: 1.5rem;
        width: 100%;
        text-align: left;
    }

    .form-messages {
        margin: 15px 0 0;
        font-size: 1.6rem;
        text-align: center;
        &.success {
            color: green;
        }
        &.error {
            color: red;
        }
    }
}