ul{
	list-style: none;
	margin: 4rem 0 4rem;
	padding:0;


	li{
		margin: 0 0 4rem;
		position: relative;
	}

	&.cash{
		li{
			padding-left: 42px;

			&:before{
				content:'';
				width: 37px;
				height: 37px;
				background: url('../images/icon-moneybag.png') no-repeat center/contain;
				position: absolute;
				top: 0px;
				left: -3px;
			}

    		@include media-breakpoint-up(xl){
				padding-left: 55px;

				&:before{
					width: 52px;
					height: 49px;
				}
			}
		}
	}

	&.red-dot{
		li{
			padding-left: 40px;

			&:before{
				content: '';
				width: 18px;
				height: 18px;
				border-radius: 50%;
				background: $red1;
				display: block;
				position: absolute;
				left: 4px;
				top: 12px;
			}

			&:after{
				content: '';
				position: absolute;
				top: 4px;
				left: -4px;
				background: rgba($red1, .2);
				width: 34px;
				height: 34px;
				border-radius: 50%;
			}

    		@include media-breakpoint-up(md){
				&:before{
					left: 0;
					top: 12px;
    			}

				&:after{
					top: 4px;
					left: -8px;
				}
			}
		}
	}
}