// Body class - hide scrollbar and account for it so no reflow
.js-modal-active {
    overflow-y: hidden;

    &.js-modal-windows{
        .site-content > section {
            padding-right: 15px; // account for body reflow when scrollbar disappears
        }
        .site-header,
        .header-logo,
        .js-modal {
            padding-right: 15px;
        }
    }
}

// Modal overlay / container
.js-modal {
    pointer-events: none; // stop click events
    opacity: 0; // make it invisible
    z-index: 9;
    display: block;
    position: fixed;
    padding-top: 30px;
    padding-bottom: 30px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    overflow: auto; /* Enable scroll if needed */
    transition: opacity 0.1s ease-in;

    @include media-breakpoint-up(sm) {
        padding-top: 50px;
    }

    &.active {
        opacity: 1;
        pointer-events: unset;
    }
}

// Modal styles
.modal {
    display: block;
    border-radius: 8px;
    padding: 20px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 30px);
    top: 40px;
    transition: top 0.4s ease-in-out;
    text-align: center;
    font-size: 1.8rem;
    max-width: 800px;
    overflow: hidden;
    background: #000;
    
    @include media-breakpoint-up(sm) {
        width: 90%;
        padding: 25px;
    }

    p{
        color:#FFF;
        margin: 0 auto 3rem;

        &.h3{
            font-size: 3.9rem;
            margin: 0 0 2rem;

            @include media-breakpoint-up(sm) {
                font-size: 5rem;
            }
        }

        &.h4{
            font-size: 4rem;
        }

        &.h3,
        &.h4{
            line-height: .94;
            letter-spacing: -.08em;
            font-family: 'Futura Condensed', sans-serif;
        }

        &.not-heading{
            @include media-breakpoint-down(sm){
                max-width: 99%;
            }
        }
    }

    .background-image-container{
        background: #000;
        z-index: -1;
    }
        
    @include media-breakpoint-up(md) {
        max-width: 670px;
        padding: 50px;
        font-size: 2rem;
    }
    
    .js-modal.active & {
        top: 0px;
    }

    .btn{
        max-width: 100%;

        &[type="submit"]{
            @include media-breakpoint-down(sm) {
                font-size: 4rem;
            }
        }
    }

    .btn-disclaimer{
          margin: 15px auto 0;
          font-size: 1.5rem;
          max-width: 450px;
    }
   
    .close-x {
        float: right;
        display: block;
        cursor: pointer;
        fill: #FFFFFF;
        position: relative;
        height: 14px;
        width: 14px;
        top: -10px;
        right: -10px;
        
        @include media-breakpoint-up(sm) {
            top: -15px;
        }

        @include media-breakpoint-up(md) {
            height: 19px;
            width: 19px;
            top: -29px;
            right: -23px;
        }
    }
}

.loading-meter {
    height: 30px;
    position: relative;
    margin-bottom: 30px;
    background: #555;
    border-radius: 25px;
    padding: 6px;
    box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);
    width: 85%;
    margin-left: auto;
    margin-right: auto;

    > span {
        text-align: center;
        color: #fff;
        line-height: 24px;
        display: block;
        height: 100%;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        background-color: $red1;
        position: relative;
        overflow: hidden;
    }
}

.loading-meter > span:after {
    content: " ";
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    background: linear-gradient(
            -45deg,
            rgba(0,0,0, .05) 25%,
            transparent 25%,
            transparent 50%,
            rgba(0,0,0, .05) 50%,
            rgba(0,0,0, .05) 75%,
            transparent 75%,
            transparent
        );
    z-index: 1;
    background-size: 50px 50px;
    animation: move 2s linear infinite;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
}

@keyframes move {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 50px 50px;
    }
}


.exitintent{
    background: rgba(#282728, .8);
    backdrop-filter: blur(40px);
    padding: 40px 0;

    @include media-breakpoint-up(xl) {
        padding: 60px 0;
    }

    @media screen and (min-width: 1200px) and (max-height: 1000px){
        padding: 40px 0;
    }


    @-moz-document url-prefix() {
        background: rgba(#282728,.8);
    }

    .modal{
        background: #d1d1d1;

        @include media-breakpoint-up(md) {
            padding: 35px 30px 45px;
            max-width: 760px;
        }

        @media screen and (min-width: 1800px){
            max-width: 930px;
        }

        @media screen and (min-width: 1200px) and (max-height: 1000px){
            padding: 35px 30px 45px;
            max-width: 760px;
        }


        .close-x{
            fill: #9e9c9c;
            width: 15px;
            height: 15px;
            top: -18px;
            right: -3px;
        }
    }

    .content{

        p:not(.h2){
            line-height: 1.2;
            color: #343434;
            margin: 0 auto;
            letter-spacing: -.04em;
            max-width: 630px;
            font-size: 2.2rem;

            @include media-breakpoint-up(md){
                font-size: 2.5rem;
            }

            @media screen and (min-width: 1800px){
                font-size: 3rem;
                max-width: 750px;
            }

            @media screen and (min-width: 1200px) and (max-height: 1000px){
                font-size: 2.2rem;
            }
        }

        .h2{
            line-height: 1;
            color:#000000;
            letter-spacing: -.025em;
            font-family: 'BarlowSemiCondensed', sans-serif;
            margin: 0 auto 2rem;
            font-size: 5rem;

            @include media-breakpoint-up(sm){
                font-size: 6rem;
            }

            @include media-breakpoint-up(md){
                font-size: 8rem;
            }

            @media screen and (min-width: 1800px){
                font-size: 10rem;
            }

            @media screen and (min-width: 1200px) and (max-height: 1000px){
                font-size: 7rem;
            }


        }

        .gif{
            max-width: 552px;
            margin: 3rem auto;

            @media screen and (min-width: 1800px){
                max-width: 720px;
                margin: 3.5rem auto;
            }

            @media screen and (min-width: 1200px) and (max-height: 1000px){
                max-width: 500px;
            }
        }

        button{
            width: 100%;
            max-width: 550px;
            font-family: 'BarlowSemiCondensed', sans-serif;
            font-weight: 400;
            font-size: 3.6rem;

            @include media-breakpoint-up(sm) {
                font-size: 4.8rem;
            }

            @include media-breakpoint-up(lg){
                max-width: 570px;
                font-size: 4.8rem;
            }

            @media screen and (min-width: 1800px){
                max-width: 730px;
                font-size: 6rem;
            }

            @media screen and (min-width: 1200px) and (max-height: 1000px){
                max-width: 570px;
                font-size: 4.8rem;
            }

            &:hover,
            &:focus,
            &:active{
                color:#FFF;
            }
        }
    }

    &__loading,
    &__graphic{
        width:100%;
        margin: 0 auto;
    }

    &__loading{
        margin: 0 auto 1rem;
    }
    
    &__graphic{
        max-width: 1075px;
        margin: 2rem auto 1.5rem;

        @include media-breakpoint-up(md) {
            margin: 3.5rem auto 0rem;
        }
    }

    .c-progress {
        position: relative;
        width: 90%;
        height: 21px;
        background: #9f9a9a;
        border-radius: 100px;
        overflow: hidden;
        border: 2px solid #a09b9b;
        margin-left: auto;
        margin-right: auto;
        box-shadow: 3px 3px 5px rgba(255,255,255,0.1);
        margin-bottom: 15px;
    }

    .c-progress__bar.c-loading {
        animation-name: loading;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    .c-progress__bar {
        transform: skewX(-40deg);
        transform-origin: bottom;
        padding-right: 45%;
        margin-left: -30px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(90deg, #ff2915 0%, #ff2915 33%);
        background-clip: content-box;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 100px;
    }

    @media screen and (min-width: 768px){
        .c-progress {
            width: 100%;
            max-width: 375px;
        }
    }

    @media screen and (min-width: 1800px){
        .c-progress{
            height: 28px;
            max-width: 480px;
            border: 4px solid #a09b9b;
            border-left-width: 5px;
        }
    }

    @media screen and (min-width: 1200px) and (max-height: 1000px){
        .c-progress {
            width: 100%;
            height: 21px;
            max-width: 375px;
        }
    }

    @keyframes loading {
      from { padding-right: 45%; }
      to { padding-right: 5%; }
    }
}




