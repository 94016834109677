.section-marquee{
	padding: 0px 0 0;
	background: none;

    @include media-breakpoint-up(md){
		padding: 40px 0 0;
    }

	.marquee-row{
		margin: 0 0 10px;

		@include media-breakpoint-up(md){
			margin: 0 0 20px;
		}

		&:nth-child(1){
			animation: cssmarquee1 110s linear infinite;
		}
		&:nth-child(2){
			animation: cssmarquee2 110s linear infinite;
		}
		&:nth-child(3){
			animation: cssmarquee1 150s linear infinite;
		}

		@include media-breakpoint-up(md){
			&:nth-child(1){
				animation: cssmarquee1 190s linear infinite;
			}
			&:nth-child(2){
				animation: cssmarquee2 190s linear infinite;
			}
			&:nth-child(3){
				animation: cssmarquee1 230s linear infinite;
			}
		}

		&__scroller{
			display: flex;
			flex-wrap: nowrap;

			@keyframes cssmarquee1{
				0% {
					transform: translateX(-220%);
				}
				100% {
					transform: translateX(-2%);
				}
			}
			@keyframes cssmarquee2{
				0% {
					transform: translateX(-2%);
				}
				100% {
					transform: translateX(-200%);
				}
			}

			div{
				font-family: $headings-font;
				font-style: italic;
				font-weight: 400;
				font-size: 4rem;
				padding: 6px 15px;
				line-height: 1;
				text-transform: uppercase;
				color: #FFFFFF;
				position: relative;
				display: block;
				white-space: nowrap;
				margin: 0 5px;

    			@include media-breakpoint-up(md){
					font-size: 6rem;
					padding: 8px 20px;
					margin: 0 9px;
    			}

				span{
					position: relative;
					z-index: 1;
				}

				&:after{
					content: '';
					position: absolute;
					top:0;
					left:0;
					width: 100%;
					height: 100%;
					background: $red1;
					transform: skewY(-7deg) rotate(7deg);
					z-index: 0;
				}

				i{
					top: -2px;
					line-height: .8;
				}
			}
		}
	}
}