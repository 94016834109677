.ribbon{
	position: relative;
	display: table;
	margin-left: auto;
	margin-right: auto;
	color:#FFF;

	&:before{
		content:'';
		width: 100%;
		height: 100%;
		position: absolute;
		top:0;
		left:0;
		z-index: 0;
		background: url('../images/ribbon-large.png') no-repeat center/100%;
	}

	&--500{
		transform: rotate(-1.8deg);
		margin-top: -5px;

		&:before{
			background: url('../images/ribbon-xl.png') no-repeat center/100%;
		}

		span{
			padding: 10px 17px 3px;
			text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
		}


	    @include media-breakpoint-up(xl){
			margin-top: -10px;

			span{
				padding: 14px 29px 0px;
			}
	    }
	}

	&--hero{
		transform: rotate(-2.85deg);
		margin-top: -20px;

		&:before{
			background: url('../images/ribbon-hero.png') no-repeat center/100%;
		}

		span{
			padding: 20px 30px 20px;
			text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
		}

	    @include media-breakpoint-up(sm){
	    	span{
				padding: 20px 40px 20px;
			}
	    }

	    @include media-breakpoint-up(lg){
			margin-top: -20px;
	    	span{
				padding: 20px 50px 20px;
			}
	    }

	    @include media-breakpoint-up(xl){
			margin-top: -28px;

			span{
				padding: 20px 75px 20px;
			}
	    }

    	@media screen and (min-width: 1400px) and (max-height: 1000px){
    		margin-top: -20px;
	    	span{
				padding: 20px 60px 20px;
			}
    	}
	}

	&--listen{
		transform: rotate(-2.48deg);
		margin: 0 auto -12px;
		
		&:before{
			background: url('../images/ribbon-large.png') no-repeat center/100%;
		}
		
		span{
			text-shadow: 0px 4.61538px 4.61538px rgba(0, 0, 0, 0.1);
			font-size: 2.8rem;
			font-family: $headings-font-2;
			letter-spacing: 0;
			font-style: italic;
			padding: 20px 35px 20px;
		}

	    @include media-breakpoint-up(md){
			margin: 0 auto -14px;
	    	span{
				font-size: 3rem;
				padding: 20px 50px 20px;
	    	}
	    }

	    @include media-breakpoint-up(xl){
	    	span{
				font-size: 4.26rem;
				padding: 27px 70px 30px;
	    	}
	    }
	}

	&--reveal{
		margin: -5px auto 0;
  		transform: rotate(-2.5deg);
		
		&:before{
			background: url('../images/ribbon-med.png') no-repeat center/100%;
		}

		span{
			font-size: 6rem;
			font-family: $headings-font;
			letter-spacing: 0;
			font-style: italic;
			padding: 15px 30px 15px;
			text-shadow: 0 4px 4px rgba(0,0,0,.2);
			transform: rotate(-1.3deg);
		}

	    @include media-breakpoint-up(smx){
			span{
				font-size: 7rem;
			}
	    }

	    @include media-breakpoint-up(sm){
	    	margin: -8px auto 0;
			span{
				font-size: 8rem;
				padding: 15px 45px 15px;
			}
	    }

	    @include media-breakpoint-up(md){
			margin: -12px auto 0;
			span{
				font-size: 10rem;
				padding: 15px 55px 15px;
			}
	    }

	    @include media-breakpoint-up(xl){
			margin: -16px auto 0;

	    	span{
				font-size: 11.8rem;
				padding: 20px 65px 22px;
			}
	    }
	}

	&--attend,
	&--register,
	&--bonus{
		transform: rotate(-1.5deg);
		margin-top: -25px;
		
		&:before{
			background: url('../images/ribbon-med.png') no-repeat center/100%;
		}
		span{
			padding: 27px 18px 30px;
			transform: rotate(-1.4deg);
			text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
		}

		@include media-breakpoint-up(lg){
			margin-top: -35px;
			span{
				padding: 32px 30px 35px;
			}
	    }
	}

	&--register{
		&:before{
			background: url('../images/ribbon-register.png') no-repeat center/100%;
		}

		@include media-breakpoint-up(lg){
			span{
				padding: 32px 25px 35px;
			}
		}
	}

	&--bonus{
		margin-top: -17px;

		&:before{
			background: url('../images/ribbon-bonus.png') no-repeat center/100%;
		}

		span{
			padding: 22px 12px 25px;
		}

		@include media-breakpoint-up(lg){
			margin-top: -32px;

			span{
				padding: 32px 22px 35px;
			}
		}
	}

	span{
		line-height: .88;
		z-index:1;
		position:relative;
		display: table;
	}
}

.loop{
	position: relative;
	display: table;
	margin-left: auto;
	margin-right: auto;

	&--inline{
		display: inline-block;
	}

	span{
		position:relative;
		z-index:1;
	}

	&--red{
		&:after{
			content:'';
			height: 110%;
			width: 110%;
			left: -30px;
			top: -7px;
			position: absolute;
			transform: rotate(-1.44deg);
			z-index: 2;
			background: url('../../src/svg/ribbon-line-red.svg') no-repeat center/contain;
		}

		span{
			display: table;
		}

	    @include media-breakpoint-up(xl){
			&:after{
				height: 116%;
				width: 114%;
				left: -63px;
				top: -16px;
			}
	    }
	}

	&--hero{
		&:after{
			content:'';
			height: 97%;
			width: 97%;
			left: 2%;
			top: 0%;
			position: absolute;
			z-index: 2;
			transform: rotate(2.85deg);
			background: url('../images/line-hero.png') no-repeat center/contain;
		}

		span{
			display: table;
		}

	    @include media-breakpoint-up(md){
	    	&:after{
				height: 106%;
				left: -2%;
				top: -5%;
				width: 106%;
			}
	    }

	    @include media-breakpoint-up(xl){
			&:after{
				left: -7%;
				top: -8%;
				width: 116%;
				height: 116%;
			}
	    }

    	@media screen and (min-width: 1400px) and (max-height: 1000px){
    		&:after{
				height: 106%;
				left: -2%;
				top: -5%;
				width: 106%;
			}
    	}
	}

	&--black{
		&:after{
			content:'';
			height: 103%;
			width: 100%;
			left: -7px;
			top: -3px;
			position: absolute;
			z-index: 2;
			transform: rotate(-1.11deg);
			background: url('../../src/svg/ribbon-line-black.svg') no-repeat center/contain;
		}

		span{
			display: table;
		}

	    @include media-breakpoint-up(lg){
			&:after{
				left: -13px;
				top: -7px;
			}
	    }
	}
}