.section-featuredin{
	h4{
		font-family: $headings-font-2;
		font-style: italic;
		font-size: 23px;
		line-height: 1.3;
		text-align: center;
		letter-spacing: 0.1em;
		color: #C9C9C9;
		margin-bottom: 5rem;

    	@include media-breakpoint-down(sm){
			margin-bottom: 3rem;
    	}
	}

	img{
		max-width: 1114px;
		margin: 0 auto;
		display: block;
	}
}