.section-copy{
	&.bg-dark{
		color:#FFF;
	}

	.h3{
		font-style: italic;
	}

	h2 + .lead{
		margin-top: 4rem;
		margin-bottom: 4rem;

        @include media-breakpoint-up(xl){
			margin-top: 5rem;
			margin-bottom: 5rem;
        }
	}

	.signature{
		width: 200px;
		max-width: 100%;
		margin: 0 0 4rem;

		@include media-breakpoint-up(lg){
			width: 300px;
		}
	}

	.img-fluid{
		margin: 0 auto 1.5rem;
	}

	.btn-section{
		margin: 5rem auto 0;
		@include media-breakpoint-up(md){
			margin: 7rem auto 0;
		}
	}

	.btn-signature{
		margin: 4.5rem auto;

		@include media-breakpoint-up(lg){
			margin: 4.5rem auto 10rem;
		}
	}

	&--attend{
		@include media-breakpoint-down(lg){
			h2{
				margin: 0 0 2rem;
			}

			ul{
				margin-top: 1.5rem;
			}
		}
	}

	&--register{
		h2{
			margin:0;
		}
		
		img.img-fluid{
			@include media-breakpoint-up(lg){
				margin:-10px auto;
			}
		}
	}

	&--bonus{
		img.img-fluid{
			@include media-breakpoint-up(lg){
				margin: -10px auto 40px;
			}
		}

		@include media-breakpoint-down(md){
			h2{
				margin-bottom: 3rem;
			}
			img.img-fluid{
				margin: 0 auto 3rem;
			}
		}
	}
}