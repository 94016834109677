// --------------------
// VARIABLES
//---------------------

// Colors
$body-color				: #000000;
$primary        		: #D94632;
$secondary      		: #333;
// $success:       $green;
// $info:          $cyan;
// $warning:       $yellow;
// $danger:        $red;
$light:         #EFEFEF;
$dark:          #000;


$red1 : #D94632;
$red2 : #7A170A;
$yellow: #FEF431;

// Header
$headerheight   		: 150px;  // Change to approximate header height - adds padding to first section
$header-starting-width	: 280px;
$header-scrolled-width	: 200px;
$headerbg       		: #fff; // Header scrolled background color.

// Tables
$bg-table-active-color 	: #42c7b3;

// Slider
$slider-title-color 	: #ffffff;
$slick-dot-color 		: #ffffff;
$slick-active-dot-color : #42c7b3;

// Grid settings
$grid-gutter-width		: 3rem !default;


$grid-breakpoints: (
  xs: 0,
  smx: 400px,
  sm: 680px,
  md: 810px,
  lg: 992px,
  xl: 1200px,
  xxl: 1350px
);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 650px,
  md: 780px,
  lg: 960px,
  xl: 1140px,
  xxl: 1270px
);