.free-swipe-file {
    form {
        background: rgba(0, 0, 0, 0.1);
        padding: 30px;
        border-radius: 30px;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }
    h3 {
        line-height: 1.1;
        margin-bottom: 20px;
    }
    input,
    button {
        max-width: 90%;
    }
    button {
        cursor: pointer;
    }
}