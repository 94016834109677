// Phone country selector
// .js-phone-country {
//     width: 100%;
//     display: flex;
//     flex-wrap: wrap;

//     .false-phone{
//         width: calc(100% - 134px);

//         @include media-breakpoint-up(md) {
//             width: calc(100% - 155px);
//         }
//     }

//     .country-selected {
//         background: #FFF url('../images/arrow-down.png') no-repeat 87% 50%/16px 10px;

//         width: auto;
//         display: inline-block;
//         border-radius: 6px;
//         border: 1px solid #cccccc;
//         padding: 0px 20px;
//         padding-right: 35px;
//         cursor: pointer;
//         font-size: 2rem;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         color: #000;
//         white-space: nowrap;
//         margin-right: 10px;
//         flex: 1 0 auto;
//         max-width: 124px;
//         height: 50px;

//         @include media-breakpoint-up(md) {
//             max-width: 145px;
//             padding-right: 45px;
//             height: 65px;
//         }

//         &:after {
//             content: ' ';
//             display: inline-block;
//             transform: rotate(-90deg);
//             height: 12px;
//             width: 10px;
//             margin-left: 5px;
//             vertical-align: middle;
//             pointer-events: none;
//         }
//     }
    
//     ul {
//         display: none;
//         position: absolute;
//         background: #fff;
//         z-index: 10;
//         height: 300px;
//         max-width: 400px;
//         overflow-y: scroll;
//         border: 1px solid #767676;
//         border-radius: 3px;
//         text-align: left;

//         &.active {
//             display: block;
//         }

//         li[data-phone-cc] {
//             list-style: none;
//             padding-left: 0;
//             margin-bottom: 0;
//             font-size: 1.8rem;
//             padding: 4px 11px;
//             cursor: pointer;

//             &:before {
//                 display: none;
//             }

//             &:hover {
//                 background: #eee;
//             }

//             span {
//                 color: #939393;
//                 pointer-events: none;
//             }
//         }
//     }
// }

.iti{
    text-align: left;

    .iti__arrow,
    [data-lastpass-icon-root]{
        // display: none;
    }

    .iti__selected-flag{
          padding: 0 15px 0 20px;
    }

    &.iti--separate-dial-code .iti__selected-flag{
        // background: #FFF url('../images/arrow-down.png') no-repeat 85% 50%/10px auto;
        border-radius: 5px;
        padding-left: 25px;
    }

    &__flag-container{
        padding: 0;
        z-index: 5;
        background: #FFF;
        border-radius: 5px;
        // background: #FFF url('../images/arrow-down.png') no-repeat 85% 50%/10px auto;
    }

    >input[type="tel"]{
        padding-left: 75px;
    }
}