.section-hero{
	padding: 3rem 0 6rem;
	text-align: center;
	background: $red1;

	@include media-breakpoint-up(smx){
		padding: 3.5rem 0 6rem;
	}

	@include media-breakpoint-up(md){
		padding: 3rem 0 6.5rem;
	}

	&__logo{
		max-width: 100px;
		margin: 0 auto;

		img{
			width: 100%;
		}

	}
	&__countdown {
		margin: 0 auto 2rem;

		.summit-countdown {
			line-height: 1.1;
		}

		@include media-breakpoint-up(smx){
			margin: 0 auto 2.5rem;
		}

		@include media-breakpoint-up(md){
			margin: 0 auto 4rem;
		}

		@include media-breakpoint-up(xxl){
			margin: 0 auto 7rem;
		}
		@media screen and (min-width: 1400px) and (max-height: 1000px){
			margin: 0 auto 4rem;
    	}
	}

	&__video{
		position: relative;
		margin: 2rem auto 2.5rem;

		@include media-breakpoint-up(smx){
			margin: 3.5rem auto 2.8rem;
		}

		.background-image-container{
			img{
				object-fit: contain;
			}
		}
	}

	h1{
		color: #FFFFFF;
		text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
		margin-bottom: 2rem;

		@include media-breakpoint-up(lg){
			margin-bottom: 4.5rem;
		}

		&.quotes{
		    @include media-breakpoint-up(md){
    			&:before,
    			&:after{
	    			font-size: 75%;
    			}

    			&:after{
    				top: -25px;
    				right: -7px;
    			}
    		}

			@include media-breakpoint-up(xl){
    			&:after{
    				top: -40px;
    				right: -7px;
    			}
    		}
		}
	}

	&__wistia{
		max-width: 80%;
		margin: 0 auto;

		.wistia_embed{
			box-shadow: 0px 0px 20px rgba(0,0,0,.3);
		}

		// remove thin black line around vid
		.w-video-wrapper{
			background: none !important;
		}

		video{
 	   		object-fit: cover !important;
		}

		@include media-breakpoint-up(xxl){
			// max-width: 765px;
			max-width: 990px;
		}
	}
	.btn{
		max-width: 765px;
		color:#FFF;
		background: #000;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

		i,span{
			color:#D2D2D2;
		}

		&:hover,
	    &:focus,
	    &:active {
			color:#FFF;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
			background: #000;
	    }
	}

	.ribbon{
		display: inline-block;
		left: 15px;

	    @include media-breakpoint-up(lg){
			left: 43px;
	    }
	}

	.eyebrow{
		margin: 0 0 1.2rem;
	}
	.eyebrow {
		font-style: normal;
		font-weight: 400;
		line-height: 1.1;
		font-size: 2rem;

		@include media-breakpoint-down(sm) {
			letter-spacing: initial;
			font-size: 1.8rem;
		}
		@media screen and (max-width: 410px) {
			font-size: 1.6rem;
		}

		&__flashing{
			background: rgba(0,0,0,.8);
			border-radius: 50px;
			padding: 5px 17px 4px;
			margin: 0 auto 2rem;
	
			display: table;

			@media screen and (max-width: 410px) {
				padding: 5px 4px 4px 8px;
				width: 100%;
				display: block;
				text-align: center;
			}
	
	
			@include media-breakpoint-up(sm){
				padding: 5px 20px;
			}
	
			@include media-breakpoint-up(md) {
				padding: 7px 40px 4px 30px;
			}
	
			@include media-breakpoint-up(lg) {
				padding: 9px 30px 7px 30px;
			}
			@include media-breakpoint-up(xl) {
				padding: 9px 30px 7px 30px;
			}
			
		}
		
		&__flashing-dot {
			background-color: $primary;
			content: " ";
			width: 16px;
			height: 16px;
			border-radius: 50%;
			margin-right: 5px;
			margin-left: -10px;
			top: -1px;
			position: relative;
			vertical-align: middle;
			display: inline-block;
			flex:none;
	
			@include media-breakpoint-up(md) {
				top: -2px;
				width: 28px;
				height: 28px;
				display: inline-block;
				margin-right: 10px;
				margin-left: -20px;
			}
	
	
			@media screen and (min-width: 992px) and (max-width: 1139px){
				top:-2px;
			}
				
			&+span{
				margin-right: 6px;
			}
	
			&:before {
				content: "";
				position: absolute;
				width: 16px;
				height: 16px;
				top: 0;
				left: 0;
				border-radius: 50%;
				background: $primary;
				box-sizing: border-box;
				z-index: 1;
				animation: smallPulse 2s ease-out infinite;
	
				@include media-breakpoint-up(md) {
					width: 28px;
					height: 28px;
				}
	
			}

			
		}
	
		&__blinking{
			-webkit-animation: blinkingText 2.2s infinite;
			animation: blinkingText 2.2s infinite;
			-webkit-transition: opacity 0.2s ease;
			transition: opacity 0.2s ease;
			color: red;
			text-transform: uppercase;
			font-weight: 800;
			font-size: 1.2rem;
			letter-spacing: 0.056em;
			vertical-align: middle;
			margin-bottom: 8px;
			background: none;
	
			&:before {
				content: " ";
				display: inline-block;
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background-color: red;
				margin-right: 8px;
			}
	
			@media (min-width: 768px) {
				font-size: 1.7rem;
				margin-bottom: 20px;
	
				&:before {
					width: 12px;
					height: 12px;
				}
			}
	
			@-webkit-keyframes blinkingText {
				0% {
					opacity: 1;
				}
				50% {
					opacity: 0;
				}
				to {
					opacity: 1;
				}
			}
			
			@keyframes blinkingText {
				0% {
					opacity: 1;
				}
				50% {
					opacity: 0;
				}
				to {
					opacity: 1;
				}
			}
		}
	
	}

	&__cash{
		width: 1600px;
		max-width: 100vw;
		position:absolute;
		left:49%;
		top:54%;
		transform: translate(-50%,-50%);

		@include media-breakpoint-up(xl){
			left:calc(50% - 13px);
			max-width: 97vw;
		}
	}

	&__seenin{
		max-width: 815px;
		margin: 6rem auto 0;

		@include media-breakpoint-up(md){
			margin: 6rem auto 2rem;
		}
	}
}