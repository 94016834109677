@charset "UTF-8";
/*!
 * Bootstrap v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #FEF431;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #D94632;
  --bs-secondary: #333;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #FEF431;
  --bs-danger: #dc3545;
  --bs-light: #EFEFEF;
  --bs-dark: #000;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)); }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.5rem; } }

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2rem; } }

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.75rem; } }

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #D94632;
  text-decoration: underline; }
  a:hover {
    color: #ae3828; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #6c757d; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 1.5rem);
  padding-left: var(--bs-gutter-x, 1.5rem);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 680px) {
  .container, .container-smx, .container-sm {
    max-width: 650px; } }

@media (min-width: 810px) {
  .container, .container-smx, .container-sm, .container-md {
    max-width: 780px; } }

@media (min-width: 992px) {
  .container, .container-smx, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-smx, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1350px) {
  .container, .container-smx, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1270px; } }

.row {
  --bs-gutter-x: 3rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.667%; }

@media (min-width: 400px) {
  .col-smx {
    flex: 1 0 0%; }
  .row-cols-smx-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-smx-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-smx-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-smx-3 > * {
    flex: 0 0 auto;
    width: 33.333%; }
  .row-cols-smx-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-smx-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-smx-6 > * {
    flex: 0 0 auto;
    width: 16.667%; } }

@media (min-width: 680px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.667%; } }

@media (min-width: 810px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.667%; } }

@media (min-width: 1350px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.667%; } }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.333%; }

.offset-2 {
  margin-left: 16.667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333%; }

.offset-5 {
  margin-left: 41.667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333%; }

.offset-8 {
  margin-left: 66.667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333%; }

.offset-11 {
  margin-left: 91.667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 400px) {
  .col-smx-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-smx-1 {
    flex: 0 0 auto;
    width: 8.333%; }
  .col-smx-2 {
    flex: 0 0 auto;
    width: 16.667%; }
  .col-smx-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-smx-4 {
    flex: 0 0 auto;
    width: 33.333%; }
  .col-smx-5 {
    flex: 0 0 auto;
    width: 41.667%; }
  .col-smx-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-smx-7 {
    flex: 0 0 auto;
    width: 58.333%; }
  .col-smx-8 {
    flex: 0 0 auto;
    width: 66.667%; }
  .col-smx-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-smx-10 {
    flex: 0 0 auto;
    width: 83.333%; }
  .col-smx-11 {
    flex: 0 0 auto;
    width: 91.667%; }
  .col-smx-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-smx-0 {
    margin-left: 0; }
  .offset-smx-1 {
    margin-left: 8.333%; }
  .offset-smx-2 {
    margin-left: 16.667%; }
  .offset-smx-3 {
    margin-left: 25%; }
  .offset-smx-4 {
    margin-left: 33.333%; }
  .offset-smx-5 {
    margin-left: 41.667%; }
  .offset-smx-6 {
    margin-left: 50%; }
  .offset-smx-7 {
    margin-left: 58.333%; }
  .offset-smx-8 {
    margin-left: 66.667%; }
  .offset-smx-9 {
    margin-left: 75%; }
  .offset-smx-10 {
    margin-left: 83.333%; }
  .offset-smx-11 {
    margin-left: 91.667%; }
  .g-smx-0,
  .gx-smx-0 {
    --bs-gutter-x: 0; }
  .g-smx-0,
  .gy-smx-0 {
    --bs-gutter-y: 0; }
  .g-smx-1,
  .gx-smx-1 {
    --bs-gutter-x: 0.25rem; }
  .g-smx-1,
  .gy-smx-1 {
    --bs-gutter-y: 0.25rem; }
  .g-smx-2,
  .gx-smx-2 {
    --bs-gutter-x: 0.5rem; }
  .g-smx-2,
  .gy-smx-2 {
    --bs-gutter-y: 0.5rem; }
  .g-smx-3,
  .gx-smx-3 {
    --bs-gutter-x: 1rem; }
  .g-smx-3,
  .gy-smx-3 {
    --bs-gutter-y: 1rem; }
  .g-smx-4,
  .gx-smx-4 {
    --bs-gutter-x: 1.5rem; }
  .g-smx-4,
  .gy-smx-4 {
    --bs-gutter-y: 1.5rem; }
  .g-smx-5,
  .gx-smx-5 {
    --bs-gutter-x: 3rem; }
  .g-smx-5,
  .gy-smx-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 680px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333%; }
  .offset-sm-2 {
    margin-left: 16.667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333%; }
  .offset-sm-5 {
    margin-left: 41.667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333%; }
  .offset-sm-8 {
    margin-left: 66.667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333%; }
  .offset-sm-11 {
    margin-left: 91.667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 810px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333%; }
  .offset-md-2 {
    margin-left: 16.667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333%; }
  .offset-md-5 {
    margin-left: 41.667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333%; }
  .offset-md-8 {
    margin-left: 66.667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333%; }
  .offset-md-11 {
    margin-left: 91.667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333%; }
  .offset-lg-2 {
    margin-left: 16.667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333%; }
  .offset-lg-5 {
    margin-left: 41.667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333%; }
  .offset-lg-8 {
    margin-left: 66.667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333%; }
  .offset-lg-11 {
    margin-left: 91.667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333%; }
  .offset-xl-2 {
    margin-left: 16.667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333%; }
  .offset-xl-5 {
    margin-left: 41.667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333%; }
  .offset-xl-8 {
    margin-left: 66.667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333%; }
  .offset-xl-11 {
    margin-left: 91.667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1350px) {
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.333%; }
  .offset-xxl-2 {
    margin-left: 16.667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.333%; }
  .offset-xxl-5 {
    margin-left: 41.667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.333%; }
  .offset-xxl-8 {
    margin-left: 66.667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.333%; }
  .offset-xxl-11 {
    margin-left: 91.667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #000000;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #000000;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #000000;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #000000;
  vertical-align: top;
  border-color: #dee2e6; }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: currentColor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-bg: #f7dad6;
  --bs-table-striped-bg: #ebcfcb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dec4c1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e4cac6;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dec4c1; }

.table-secondary {
  --bs-table-bg: #d6d6d6;
  --bs-table-striped-bg: #cbcbcb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c1c1c1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c6c6c6;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c1c1c1; }

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7; }

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3; }

.table-warning {
  --bs-table-bg: #fffdd6;
  --bs-table-striped-bg: #f2f0cb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6e4c1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #eceac6;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6e4c1; }

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4; }

.table-light {
  --bs-table-bg: #EFEFEF;
  --bs-table-striped-bg: #e3e3e3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d7d7d7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #dddddd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #d7d7d7; }

.table-dark {
  --bs-table-bg: #000;
  --bs-table-striped-bg: #0d0d0d;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #1a1a1a;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #131313;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #1a1a1a; }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 399.98px) {
  .table-responsive-smx {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 679.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 809.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1349.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: #000000;
    background-color: #fff;
    border-color: #eca399;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(217, 70, 50, 0.25); }
  .form-control::-webkit-date-and-time-value {
    height: 1.5em; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #000000;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3; }
  .form-control::-webkit-file-upload-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #000000;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::-webkit-file-upload-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #000000;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }
  .form-control-sm::-webkit-file-upload-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }
  .form-control-lg::-webkit-file-upload-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + (0.75rem + 2px)); }

textarea.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px)); }

textarea.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px)); }

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    height: 1.5em;
    border-radius: 0.25rem; }
  .form-control-color::-webkit-color-swatch {
    height: 1.5em;
    border-radius: 0.25rem; }

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #eca399;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(217, 70, 50, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    background-color: #e9ecef; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000000; }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #eca399;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(217, 70, 50, 0.25); }
  .form-check-input:checked {
    background-color: #D94632;
    border-color: #D94632; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #D94632;
    border-color: #D94632;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23eca399'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(217, 70, 50, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(217, 70, 50, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #D94632;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #f4c8c2; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #D94632;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #f4c8c2; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control {
    padding: 1rem 0.75rem; }
    .form-floating > .form-control::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus {
    z-index: 3; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 3; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.188rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.188rem) right calc(0.375em + 0.188rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #198754; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #198754; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1; }
  .was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated
  .input-group .form-select:valid:focus,
  .input-group .form-select.is-valid:focus {
    z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.188rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.188rem) right calc(0.375em + 0.188rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #dc3545; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2; }
  .was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated
  .input-group .form-select:invalid:focus,
  .input-group .form-select.is-invalid:focus {
    z-index: 3; }

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #000000; }
  .btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(217, 70, 50, 0.25); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65; }

.btn-primary {
  color: #000;
  background-color: #D94632;
  border-color: #D94632; }
  .btn-primary:hover {
    color: #000;
    background-color: #df6251;
    border-color: #dd5947; }
  .btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #000;
    background-color: #df6251;
    border-color: #dd5947;
    box-shadow: 0 0 0 0.25rem rgba(184, 60, 43, 0.5); }
  .btn-check:checked + .btn-primary,
  .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    color: #000;
    background-color: #e16b5b;
    border-color: #dd5947; }
    .btn-check:checked + .btn-primary:focus,
    .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(184, 60, 43, 0.5); }
  .btn-primary:disabled, .btn-primary.disabled {
    color: #000;
    background-color: #D94632;
    border-color: #D94632; }

.btn-secondary {
  color: #fff;
  background-color: #333;
  border-color: #333; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #2b2b2b;
    border-color: #292929; }
  .btn-check:focus + .btn-secondary, .btn-secondary:focus {
    color: #fff;
    background-color: #2b2b2b;
    border-color: #292929;
    box-shadow: 0 0 0 0.25rem rgba(82, 82, 82, 0.5); }
  .btn-check:checked + .btn-secondary,
  .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #292929;
    border-color: #262626; }
    .btn-check:checked + .btn-secondary:focus,
    .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(82, 82, 82, 0.5); }
  .btn-secondary:disabled, .btn-secondary.disabled {
    color: #fff;
    background-color: #333;
    border-color: #333; }

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754; }
  .btn-success:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43; }
  .btn-check:focus + .btn-success, .btn-success:focus {
    color: #fff;
    background-color: #157347;
    border-color: #146c43;
    box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5); }
  .btn-check:checked + .btn-success,
  .btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #146c43;
    border-color: #13653f; }
    .btn-check:checked + .btn-success:focus,
    .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5); }
  .btn-success:disabled, .btn-success.disabled {
    color: #fff;
    background-color: #198754;
    border-color: #198754; }

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0; }
  .btn-info:hover {
    color: #000;
    background-color: #31d2f2;
    border-color: #25cff2; }
  .btn-check:focus + .btn-info, .btn-info:focus {
    color: #000;
    background-color: #31d2f2;
    border-color: #25cff2;
    box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5); }
  .btn-check:checked + .btn-info,
  .btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #000;
    background-color: #3dd5f3;
    border-color: #25cff2; }
    .btn-check:checked + .btn-info:focus,
    .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5); }
  .btn-info:disabled, .btn-info.disabled {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }

.btn-warning {
  color: #000;
  background-color: #FEF431;
  border-color: #FEF431; }
  .btn-warning:hover {
    color: #000;
    background-color: #fef650;
    border-color: #fef546; }
  .btn-check:focus + .btn-warning, .btn-warning:focus {
    color: #000;
    background-color: #fef650;
    border-color: #fef546;
    box-shadow: 0 0 0 0.25rem rgba(216, 207, 42, 0.5); }
  .btn-check:checked + .btn-warning,
  .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    color: #000;
    background-color: #fef65a;
    border-color: #fef546; }
    .btn-check:checked + .btn-warning:focus,
    .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(216, 207, 42, 0.5); }
  .btn-warning:disabled, .btn-warning.disabled {
    color: #000;
    background-color: #FEF431;
    border-color: #FEF431; }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37; }
  .btn-check:focus + .btn-danger, .btn-danger:focus {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37;
    box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }
  .btn-check:checked + .btn-danger,
  .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #b02a37;
    border-color: #a52834; }
    .btn-check:checked + .btn-danger:focus,
    .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }
  .btn-danger:disabled, .btn-danger.disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }

.btn-light {
  color: #000;
  background-color: #EFEFEF;
  border-color: #EFEFEF; }
  .btn-light:hover {
    color: #000;
    background-color: #f1f1f1;
    border-color: #f1f1f1; }
  .btn-check:focus + .btn-light, .btn-light:focus {
    color: #000;
    background-color: #f1f1f1;
    border-color: #f1f1f1;
    box-shadow: 0 0 0 0.25rem rgba(203, 203, 203, 0.5); }
  .btn-check:checked + .btn-light,
  .btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    color: #000;
    background-color: #f2f2f2;
    border-color: #f1f1f1; }
    .btn-check:checked + .btn-light:focus,
    .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(203, 203, 203, 0.5); }
  .btn-light:disabled, .btn-light.disabled {
    color: #000;
    background-color: #EFEFEF;
    border-color: #EFEFEF; }

.btn-dark {
  color: #fff;
  background-color: #000;
  border-color: #000; }
  .btn-dark:hover {
    color: #fff;
    background-color: black;
    border-color: black; }
  .btn-check:focus + .btn-dark, .btn-dark:focus {
    color: #fff;
    background-color: black;
    border-color: black;
    box-shadow: 0 0 0 0.25rem rgba(38, 38, 38, 0.5); }
  .btn-check:checked + .btn-dark,
  .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
    .btn-check:checked + .btn-dark:focus,
    .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(38, 38, 38, 0.5); }
  .btn-dark:disabled, .btn-dark.disabled {
    color: #fff;
    background-color: #000;
    border-color: #000; }

.btn-outline-primary {
  color: #D94632;
  border-color: #D94632; }
  .btn-outline-primary:hover {
    color: #000;
    background-color: #D94632;
    border-color: #D94632; }
  .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.25rem rgba(217, 70, 50, 0.5); }
  .btn-check:checked + .btn-outline-primary,
  .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #000;
    background-color: #D94632;
    border-color: #D94632; }
    .btn-check:checked + .btn-outline-primary:focus,
    .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(217, 70, 50, 0.5); }
  .btn-outline-primary:disabled, .btn-outline-primary.disabled {
    color: #D94632;
    background-color: transparent; }

.btn-outline-secondary {
  color: #333;
  border-color: #333; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #333;
    border-color: #333; }
  .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.25rem rgba(51, 51, 51, 0.5); }
  .btn-check:checked + .btn-outline-secondary,
  .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: #fff;
    background-color: #333;
    border-color: #333; }
    .btn-check:checked + .btn-outline-secondary:focus,
    .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(51, 51, 51, 0.5); }
  .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
    color: #333;
    background-color: transparent; }

.btn-outline-success {
  color: #198754;
  border-color: #198754; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #198754;
    border-color: #198754; }
  .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5); }
  .btn-check:checked + .btn-outline-success,
  .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #fff;
    background-color: #198754;
    border-color: #198754; }
    .btn-check:checked + .btn-outline-success:focus,
    .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5); }
  .btn-outline-success:disabled, .btn-outline-success.disabled {
    color: #198754;
    background-color: transparent; }

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0; }
  .btn-outline-info:hover {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }
  .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5); }
  .btn-check:checked + .btn-outline-info,
  .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }
    .btn-check:checked + .btn-outline-info:focus,
    .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5); }
  .btn-outline-info:disabled, .btn-outline-info.disabled {
    color: #0dcaf0;
    background-color: transparent; }

.btn-outline-warning {
  color: #FEF431;
  border-color: #FEF431; }
  .btn-outline-warning:hover {
    color: #000;
    background-color: #FEF431;
    border-color: #FEF431; }
  .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
    box-shadow: 0 0 0 0.25rem rgba(254, 244, 49, 0.5); }
  .btn-check:checked + .btn-outline-warning,
  .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
    color: #000;
    background-color: #FEF431;
    border-color: #FEF431; }
    .btn-check:checked + .btn-outline-warning:focus,
    .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(254, 244, 49, 0.5); }
  .btn-outline-warning:disabled, .btn-outline-warning.disabled {
    color: #FEF431;
    background-color: transparent; }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }
  .btn-check:checked + .btn-outline-danger,
  .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-check:checked + .btn-outline-danger:focus,
    .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger:disabled, .btn-outline-danger.disabled {
    color: #dc3545;
    background-color: transparent; }

.btn-outline-light {
  color: #EFEFEF;
  border-color: #EFEFEF; }
  .btn-outline-light:hover {
    color: #000;
    background-color: #EFEFEF;
    border-color: #EFEFEF; }
  .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
    box-shadow: 0 0 0 0.25rem rgba(239, 239, 239, 0.5); }
  .btn-check:checked + .btn-outline-light,
  .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #000;
    background-color: #EFEFEF;
    border-color: #EFEFEF; }
    .btn-check:checked + .btn-outline-light:focus,
    .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(239, 239, 239, 0.5); }
  .btn-outline-light:disabled, .btn-outline-light.disabled {
    color: #EFEFEF;
    background-color: transparent; }

.btn-outline-dark {
  color: #000;
  border-color: #000; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
    box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.5); }
  .btn-check:checked + .btn-outline-dark,
  .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
    color: #fff;
    background-color: #000;
    border-color: #000; }
    .btn-check:checked + .btn-outline-dark:focus,
    .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.5); }
  .btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #000;
    background-color: transparent; }

.btn-link {
  font-weight: 400;
  color: #D94632;
  text-decoration: underline; }
  .btn-link:hover {
    color: #ae3828; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #000000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: 0.125rem; }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 400px) {
  .dropdown-menu-smx-start {
    --bs-position: start; }
    .dropdown-menu-smx-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-smx-end {
    --bs-position: end; }
    .dropdown-menu-smx-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 680px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 810px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1350px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15); }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #1e2125;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #D94632; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529; }

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item {
    color: #dee2e6; }
    .dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.15); }
    .dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
      color: #fff;
      background-color: #D94632; }
    .dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
      color: #adb5bd; }
  .dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item-text {
    color: #dee2e6; }
  .dropdown-menu-dark .dropdown-header {
    color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.562rem;
  padding-left: 0.562rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #D94632;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: #ae3828; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6;
      isolation: isolate; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #D94632; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: 0.312rem;
  padding-bottom: 0.312rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.25rem; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 400px) {
  .navbar-expand-smx {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-smx .navbar-nav {
      flex-direction: row; }
      .navbar-expand-smx .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-smx .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-smx .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-smx .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-smx .navbar-toggler {
      display: none; } }

@media (min-width: 680px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (min-width: 810px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (min-width: 1350px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem; }

.card-title {
  margin-bottom: 0.5rem; }

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1rem; }

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-group > .card {
  margin-bottom: 1.5rem; }

@media (min-width: 680px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #000000;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: #c33f2d;
    background-color: #fbedeb;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125); }
    .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23c33f2d'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      transform: rotate(-180deg); }
  .accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: #eca399;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(217, 70, 50, 0.25); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .accordion-item:first-of-type {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem; }

.accordion-body {
  padding: 1rem 1.25rem; }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button {
    border-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  color: #D94632;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: #ae3828;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    color: #ae3828;
    background-color: #e9ecef;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(217, 70, 50, 0.25); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #D94632;
  border-color: #D94632; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6; }

.page-link {
  padding: 0.375rem 0.75rem; }

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }

.alert-primary {
  color: #822a1e;
  background-color: #f7dad6;
  border-color: #f4c8c2; }
  .alert-primary .alert-link {
    color: #682218; }

.alert-secondary {
  color: #1f1f1f;
  background-color: #d6d6d6;
  border-color: #c2c2c2; }
  .alert-secondary .alert-link {
    color: #191919; }

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc; }
  .alert-success .alert-link {
    color: #0c4128; }

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb; }
  .alert-info .alert-link {
    color: #04414d; }

.alert-warning {
  color: #666214;
  background-color: #fffdd6;
  border-color: #fffcc1; }
  .alert-warning .alert-link {
    color: #524e10; }

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7; }
  .alert-danger .alert-link {
    color: #6a1a21; }

.alert-light {
  color: #606060;
  background-color: #fcfcfc;
  border-color: #fafafa; }
  .alert-light .alert-link {
    color: #4d4d4d; }

.alert-dark {
  color: black;
  background-color: #cccccc;
  border-color: #b3b3b3; }
  .alert-dark .alert-link {
    color: black; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #D94632;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > li::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #000000;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #D94632;
    border-color: #D94632; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 400px) {
  .list-group-horizontal-smx {
    flex-direction: row; }
    .list-group-horizontal-smx > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-smx > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-smx > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-smx > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-smx > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 680px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 810px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1350px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #822a1e;
  background-color: #f7dad6; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #822a1e;
    background-color: #dec4c1; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #822a1e;
    border-color: #822a1e; }

.list-group-item-secondary {
  color: #1f1f1f;
  background-color: #d6d6d6; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #1f1f1f;
    background-color: #c1c1c1; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #1f1f1f;
    border-color: #1f1f1f; }

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #0f5132;
    background-color: #bcd0c7; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0f5132;
    border-color: #0f5132; }

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #055160;
    background-color: #badce3; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #055160;
    border-color: #055160; }

.list-group-item-warning {
  color: #666214;
  background-color: #fffdd6; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #666214;
    background-color: #e6e4c1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #666214;
    border-color: #666214; }

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #842029;
    background-color: #dfc2c4; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #842029;
    border-color: #842029; }

.list-group-item-light {
  color: #606060;
  background-color: #fcfcfc; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #606060;
    background-color: #e3e3e3; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #606060;
    border-color: #606060; }

.list-group-item-dark {
  color: black;
  background-color: #cccccc; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: black;
    background-color: #b8b8b8; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: black;
    border-color: black; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5; }
  .btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75; }
  .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(217, 70, 50, 0.25);
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }
  .toast:not(.showing):not(.show) {
    opacity: 0; }
  .toast.hide {
    display: none; }

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: 1.5rem; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }
  .toast-header .btn-close {
    margin-right: -0.375rem;
    margin-left: 0.75rem; }

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word; }

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
    bottom: 0; }
    .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
      top: -1px;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
      right: -1px;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
    top: 0; }
    .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
      bottom: -1px;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
      left: -1px;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem; }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff; }

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff; }

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff; }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0; }

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 1rem 1rem;
  color: #000000; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem; }
  .offcanvas-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin-top: -0.5rem;
    margin-right: -0.5rem;
    margin-bottom: -0.5rem; }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5; }

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto; }

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%); }

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%); }

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%); }

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%); }

.offcanvas.show {
  transform: none; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.link-primary {
  color: #D94632; }
  .link-primary:hover, .link-primary:focus {
    color: #e16b5b; }

.link-secondary {
  color: #333; }
  .link-secondary:hover, .link-secondary:focus {
    color: #292929; }

.link-success {
  color: #198754; }
  .link-success:hover, .link-success:focus {
    color: #146c43; }

.link-info {
  color: #0dcaf0; }
  .link-info:hover, .link-info:focus {
    color: #3dd5f3; }

.link-warning {
  color: #FEF431; }
  .link-warning:hover, .link-warning:focus {
    color: #fef65a; }

.link-danger {
  color: #dc3545; }
  .link-danger:hover, .link-danger:focus {
    color: #b02a37; }

.link-light {
  color: #EFEFEF; }
  .link-light:hover, .link-light:focus {
    color: #f2f2f2; }

.link-dark {
  color: #000; }
  .link-dark:hover, .link-dark:focus {
    color: black; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

@media (min-width: 400px) {
  .sticky-smx-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 680px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 810px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1350px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: 1px solid #dee2e6 !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: 1px solid #dee2e6 !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #D94632 !important; }

.border-secondary {
  border-color: #333 !important; }

.border-success {
  border-color: #198754 !important; }

.border-info {
  border-color: #0dcaf0 !important; }

.border-warning {
  border-color: #FEF431 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #EFEFEF !important; }

.border-dark {
  border-color: #000 !important; }

.border-white {
  border-color: #fff !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important; }

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important; }

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important; }

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-5 {
  font-size: 1.25rem !important; }

.fs-6 {
  font-size: 1rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  color: #D94632 !important; }

.text-secondary {
  color: #333 !important; }

.text-success {
  color: #198754 !important; }

.text-info {
  color: #0dcaf0 !important; }

.text-warning {
  color: #FEF431 !important; }

.text-danger {
  color: #dc3545 !important; }

.text-light {
  color: #EFEFEF !important; }

.text-dark {
  color: #000 !important; }

.text-white {
  color: #fff !important; }

.text-body {
  color: #000000 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  color: inherit !important; }

.bg-primary {
  background-color: #D94632 !important; }

.bg-secondary {
  background-color: #333 !important; }

.bg-success {
  background-color: #198754 !important; }

.bg-info {
  background-color: #0dcaf0 !important; }

.bg-warning {
  background-color: #FEF431 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

.bg-light {
  background-color: #EFEFEF !important; }

.bg-dark {
  background-color: #000 !important; }

.bg-body {
  background-color: #fff !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: 0.2rem !important; }

.rounded-2 {
  border-radius: 0.25rem !important; }

.rounded-3 {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 400px) {
  .float-smx-start {
    float: left !important; }
  .float-smx-end {
    float: right !important; }
  .float-smx-none {
    float: none !important; }
  .d-smx-inline {
    display: inline !important; }
  .d-smx-inline-block {
    display: inline-block !important; }
  .d-smx-block {
    display: block !important; }
  .d-smx-grid {
    display: grid !important; }
  .d-smx-table {
    display: table !important; }
  .d-smx-table-row {
    display: table-row !important; }
  .d-smx-table-cell {
    display: table-cell !important; }
  .d-smx-flex {
    display: flex !important; }
  .d-smx-inline-flex {
    display: inline-flex !important; }
  .d-smx-none {
    display: none !important; }
  .flex-smx-fill {
    flex: 1 1 auto !important; }
  .flex-smx-row {
    flex-direction: row !important; }
  .flex-smx-column {
    flex-direction: column !important; }
  .flex-smx-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-smx-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-smx-grow-0 {
    flex-grow: 0 !important; }
  .flex-smx-grow-1 {
    flex-grow: 1 !important; }
  .flex-smx-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-smx-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-smx-wrap {
    flex-wrap: wrap !important; }
  .flex-smx-nowrap {
    flex-wrap: nowrap !important; }
  .flex-smx-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-smx-0 {
    gap: 0 !important; }
  .gap-smx-1 {
    gap: 0.25rem !important; }
  .gap-smx-2 {
    gap: 0.5rem !important; }
  .gap-smx-3 {
    gap: 1rem !important; }
  .gap-smx-4 {
    gap: 1.5rem !important; }
  .gap-smx-5 {
    gap: 3rem !important; }
  .justify-content-smx-start {
    justify-content: flex-start !important; }
  .justify-content-smx-end {
    justify-content: flex-end !important; }
  .justify-content-smx-center {
    justify-content: center !important; }
  .justify-content-smx-between {
    justify-content: space-between !important; }
  .justify-content-smx-around {
    justify-content: space-around !important; }
  .justify-content-smx-evenly {
    justify-content: space-evenly !important; }
  .align-items-smx-start {
    align-items: flex-start !important; }
  .align-items-smx-end {
    align-items: flex-end !important; }
  .align-items-smx-center {
    align-items: center !important; }
  .align-items-smx-baseline {
    align-items: baseline !important; }
  .align-items-smx-stretch {
    align-items: stretch !important; }
  .align-content-smx-start {
    align-content: flex-start !important; }
  .align-content-smx-end {
    align-content: flex-end !important; }
  .align-content-smx-center {
    align-content: center !important; }
  .align-content-smx-between {
    align-content: space-between !important; }
  .align-content-smx-around {
    align-content: space-around !important; }
  .align-content-smx-stretch {
    align-content: stretch !important; }
  .align-self-smx-auto {
    align-self: auto !important; }
  .align-self-smx-start {
    align-self: flex-start !important; }
  .align-self-smx-end {
    align-self: flex-end !important; }
  .align-self-smx-center {
    align-self: center !important; }
  .align-self-smx-baseline {
    align-self: baseline !important; }
  .align-self-smx-stretch {
    align-self: stretch !important; }
  .order-smx-first {
    order: -1 !important; }
  .order-smx-0 {
    order: 0 !important; }
  .order-smx-1 {
    order: 1 !important; }
  .order-smx-2 {
    order: 2 !important; }
  .order-smx-3 {
    order: 3 !important; }
  .order-smx-4 {
    order: 4 !important; }
  .order-smx-5 {
    order: 5 !important; }
  .order-smx-last {
    order: 6 !important; }
  .m-smx-0 {
    margin: 0 !important; }
  .m-smx-1 {
    margin: 0.25rem !important; }
  .m-smx-2 {
    margin: 0.5rem !important; }
  .m-smx-3 {
    margin: 1rem !important; }
  .m-smx-4 {
    margin: 1.5rem !important; }
  .m-smx-5 {
    margin: 3rem !important; }
  .m-smx-auto {
    margin: auto !important; }
  .mx-smx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-smx-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-smx-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-smx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-smx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-smx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-smx-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-smx-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-smx-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-smx-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-smx-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-smx-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-smx-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-smx-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-smx-0 {
    margin-top: 0 !important; }
  .mt-smx-1 {
    margin-top: 0.25rem !important; }
  .mt-smx-2 {
    margin-top: 0.5rem !important; }
  .mt-smx-3 {
    margin-top: 1rem !important; }
  .mt-smx-4 {
    margin-top: 1.5rem !important; }
  .mt-smx-5 {
    margin-top: 3rem !important; }
  .mt-smx-auto {
    margin-top: auto !important; }
  .me-smx-0 {
    margin-right: 0 !important; }
  .me-smx-1 {
    margin-right: 0.25rem !important; }
  .me-smx-2 {
    margin-right: 0.5rem !important; }
  .me-smx-3 {
    margin-right: 1rem !important; }
  .me-smx-4 {
    margin-right: 1.5rem !important; }
  .me-smx-5 {
    margin-right: 3rem !important; }
  .me-smx-auto {
    margin-right: auto !important; }
  .mb-smx-0 {
    margin-bottom: 0 !important; }
  .mb-smx-1 {
    margin-bottom: 0.25rem !important; }
  .mb-smx-2 {
    margin-bottom: 0.5rem !important; }
  .mb-smx-3 {
    margin-bottom: 1rem !important; }
  .mb-smx-4 {
    margin-bottom: 1.5rem !important; }
  .mb-smx-5 {
    margin-bottom: 3rem !important; }
  .mb-smx-auto {
    margin-bottom: auto !important; }
  .ms-smx-0 {
    margin-left: 0 !important; }
  .ms-smx-1 {
    margin-left: 0.25rem !important; }
  .ms-smx-2 {
    margin-left: 0.5rem !important; }
  .ms-smx-3 {
    margin-left: 1rem !important; }
  .ms-smx-4 {
    margin-left: 1.5rem !important; }
  .ms-smx-5 {
    margin-left: 3rem !important; }
  .ms-smx-auto {
    margin-left: auto !important; }
  .p-smx-0 {
    padding: 0 !important; }
  .p-smx-1 {
    padding: 0.25rem !important; }
  .p-smx-2 {
    padding: 0.5rem !important; }
  .p-smx-3 {
    padding: 1rem !important; }
  .p-smx-4 {
    padding: 1.5rem !important; }
  .p-smx-5 {
    padding: 3rem !important; }
  .px-smx-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-smx-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-smx-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-smx-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-smx-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-smx-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-smx-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-smx-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-smx-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-smx-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-smx-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-smx-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-smx-0 {
    padding-top: 0 !important; }
  .pt-smx-1 {
    padding-top: 0.25rem !important; }
  .pt-smx-2 {
    padding-top: 0.5rem !important; }
  .pt-smx-3 {
    padding-top: 1rem !important; }
  .pt-smx-4 {
    padding-top: 1.5rem !important; }
  .pt-smx-5 {
    padding-top: 3rem !important; }
  .pe-smx-0 {
    padding-right: 0 !important; }
  .pe-smx-1 {
    padding-right: 0.25rem !important; }
  .pe-smx-2 {
    padding-right: 0.5rem !important; }
  .pe-smx-3 {
    padding-right: 1rem !important; }
  .pe-smx-4 {
    padding-right: 1.5rem !important; }
  .pe-smx-5 {
    padding-right: 3rem !important; }
  .pb-smx-0 {
    padding-bottom: 0 !important; }
  .pb-smx-1 {
    padding-bottom: 0.25rem !important; }
  .pb-smx-2 {
    padding-bottom: 0.5rem !important; }
  .pb-smx-3 {
    padding-bottom: 1rem !important; }
  .pb-smx-4 {
    padding-bottom: 1.5rem !important; }
  .pb-smx-5 {
    padding-bottom: 3rem !important; }
  .ps-smx-0 {
    padding-left: 0 !important; }
  .ps-smx-1 {
    padding-left: 0.25rem !important; }
  .ps-smx-2 {
    padding-left: 0.5rem !important; }
  .ps-smx-3 {
    padding-left: 1rem !important; }
  .ps-smx-4 {
    padding-left: 1.5rem !important; }
  .ps-smx-5 {
    padding-left: 3rem !important; }
  .text-smx-start {
    text-align: left !important; }
  .text-smx-end {
    text-align: right !important; }
  .text-smx-center {
    text-align: center !important; } }

@media (min-width: 680px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 810px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1350px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important; }
  .fs-2 {
    font-size: 2rem !important; }
  .fs-3 {
    font-size: 1.75rem !important; }
  .fs-4 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

/* purgecss start ignore */
.iti {
  position: relative;
  display: inline-block;
  width: 100%; }

.iti * {
  box-sizing: border-box; }

.iti__hide {
  display: none; }

.iti__v-hide {
  visibility: hidden; }

.iti input,
.iti input[type=text],
.iti input[type=tel] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0; }

.iti__flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px; }

.iti__selected-flag {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 6px 0 8px; }

.iti__arrow {
  margin-left: 6px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555; }

[dir=rtl] .iti__arrow {
  margin-right: 6px;
  margin-left: 0; }

.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #555; }

.iti__country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 1px solid #CCC;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }

.iti__country-list--dropup {
  bottom: 100%;
  margin-bottom: -1px; }

@media (max-width: 500px) {
  .iti__country-list {
    white-space: normal; } }

.iti__flag-box {
  display: inline-block;
  width: 20px; }

.iti__divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #CCC; }

.iti__country {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  outline: none;
  font-size: 1.75rem;
  margin: 0; }

.iti__dial-code {
  color: #999; }

.iti__country.iti__highlight {
  background-color: rgba(0, 0, 0, 0.05); }

.iti__flag-box,
.iti__country-name {
  margin-right: 12px; }

[dir=rtl] .iti__flag-box,
[dir=rtl] .iti__country-name {
  margin-right: 0;
  margin-left: 6px; }

.iti--allow-dropdown input,
.iti--allow-dropdown input[type=text],
.iti--allow-dropdown input[type=tel],
.iti--separate-dial-code input,
.iti--separate-dial-code input[type=text],
.iti--separate-dial-code input[type=tel] {
  margin-left: 0; }

[dir=rtl] .iti--allow-dropdown input,
[dir=rtl] .iti--allow-dropdown input[type=text],
[dir=rtl] .iti--allow-dropdown input[type=tel],
[dir=rtl] .iti--separate-dial-code input,
[dir=rtl] .iti--separate-dial-code input[type=text],
[dir=rtl] .iti--separate-dial-code input[type=tel] {
  margin-right: 0; }

.iti--allow-dropdown .iti__flag-container,
.iti--separate-dial-code .iti__flag-container {
  right: auto;
  left: 0; }

[dir=rtl] .iti--allow-dropdown .iti__flag-container,
[dir=rtl] .iti--separate-dial-code .iti__flag-container {
  right: 0;
  left: auto; }

.iti--allow-dropdown .iti__flag-container:hover {
  cursor: pointer; }

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05); }

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover {
  cursor: default; }

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover .iti__selected-flag,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent; }

.iti--separate-dial-code .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05); }

.iti--separate-dial-code.iti--show-flags .iti__selected-dial-code {
  margin-left: 6px; }

.iti--container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px; }

.iti--container:hover {
  cursor: pointer; }

.iti-mobile .iti--container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed; }

.iti-mobile .iti__country-list {
  max-height: 100%;
  width: 100%; }

.iti-mobile .iti__country {
  padding: 10px 10px;
  line-height: 1.5em; }

.iti__flag {
  width: 20px; }

.iti__flag.iti__be {
  width: 18px; }

.iti__flag.iti__ch {
  width: 15px; }

.iti__flag.iti__mc {
  width: 19px; }

.iti__flag.iti__ne {
  width: 18px; }

.iti__flag.iti__np {
  width: 13px; }

.iti__flag.iti__va {
  width: 15px; }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-size: 5652px 15px; } }

.iti__flag.iti__ac {
  height: 10px;
  background-position: 0px 0px; }

.iti__flag.iti__ad {
  height: 14px;
  background-position: -22px 0px; }

.iti__flag.iti__ae {
  height: 10px;
  background-position: -44px 0px; }

.iti__flag.iti__af {
  height: 14px;
  background-position: -66px 0px; }

.iti__flag.iti__ag {
  height: 14px;
  background-position: -88px 0px; }

.iti__flag.iti__ai {
  height: 10px;
  background-position: -110px 0px; }

.iti__flag.iti__al {
  height: 15px;
  background-position: -132px 0px; }

.iti__flag.iti__am {
  height: 10px;
  background-position: -154px 0px; }

.iti__flag.iti__ao {
  height: 14px;
  background-position: -176px 0px; }

.iti__flag.iti__aq {
  height: 14px;
  background-position: -198px 0px; }

.iti__flag.iti__ar {
  height: 13px;
  background-position: -220px 0px; }

.iti__flag.iti__as {
  height: 10px;
  background-position: -242px 0px; }

.iti__flag.iti__at {
  height: 14px;
  background-position: -264px 0px; }

.iti__flag.iti__au {
  height: 10px;
  background-position: -286px 0px; }

.iti__flag.iti__aw {
  height: 14px;
  background-position: -308px 0px; }

.iti__flag.iti__ax {
  height: 13px;
  background-position: -330px 0px; }

.iti__flag.iti__az {
  height: 10px;
  background-position: -352px 0px; }

.iti__flag.iti__ba {
  height: 10px;
  background-position: -374px 0px; }

.iti__flag.iti__bb {
  height: 14px;
  background-position: -396px 0px; }

.iti__flag.iti__bd {
  height: 12px;
  background-position: -418px 0px; }

.iti__flag.iti__be {
  height: 15px;
  background-position: -440px 0px; }

.iti__flag.iti__bf {
  height: 14px;
  background-position: -460px 0px; }

.iti__flag.iti__bg {
  height: 12px;
  background-position: -482px 0px; }

.iti__flag.iti__bh {
  height: 12px;
  background-position: -504px 0px; }

.iti__flag.iti__bi {
  height: 12px;
  background-position: -526px 0px; }

.iti__flag.iti__bj {
  height: 14px;
  background-position: -548px 0px; }

.iti__flag.iti__bl {
  height: 14px;
  background-position: -570px 0px; }

.iti__flag.iti__bm {
  height: 10px;
  background-position: -592px 0px; }

.iti__flag.iti__bn {
  height: 10px;
  background-position: -614px 0px; }

.iti__flag.iti__bo {
  height: 14px;
  background-position: -636px 0px; }

.iti__flag.iti__bq {
  height: 14px;
  background-position: -658px 0px; }

.iti__flag.iti__br {
  height: 14px;
  background-position: -680px 0px; }

.iti__flag.iti__bs {
  height: 10px;
  background-position: -702px 0px; }

.iti__flag.iti__bt {
  height: 14px;
  background-position: -724px 0px; }

.iti__flag.iti__bv {
  height: 15px;
  background-position: -746px 0px; }

.iti__flag.iti__bw {
  height: 14px;
  background-position: -768px 0px; }

.iti__flag.iti__by {
  height: 10px;
  background-position: -790px 0px; }

.iti__flag.iti__bz {
  height: 14px;
  background-position: -812px 0px; }

.iti__flag.iti__ca {
  height: 10px;
  background-position: -834px 0px; }

.iti__flag.iti__cc {
  height: 10px;
  background-position: -856px 0px; }

.iti__flag.iti__cd {
  height: 15px;
  background-position: -878px 0px; }

.iti__flag.iti__cf {
  height: 14px;
  background-position: -900px 0px; }

.iti__flag.iti__cg {
  height: 14px;
  background-position: -922px 0px; }

.iti__flag.iti__ch {
  height: 15px;
  background-position: -944px 0px; }

.iti__flag.iti__ci {
  height: 14px;
  background-position: -961px 0px; }

.iti__flag.iti__ck {
  height: 10px;
  background-position: -983px 0px; }

.iti__flag.iti__cl {
  height: 14px;
  background-position: -1005px 0px; }

.iti__flag.iti__cm {
  height: 14px;
  background-position: -1027px 0px; }

.iti__flag.iti__cn {
  height: 14px;
  background-position: -1049px 0px; }

.iti__flag.iti__co {
  height: 14px;
  background-position: -1071px 0px; }

.iti__flag.iti__cp {
  height: 14px;
  background-position: -1093px 0px; }

.iti__flag.iti__cr {
  height: 12px;
  background-position: -1115px 0px; }

.iti__flag.iti__cu {
  height: 10px;
  background-position: -1137px 0px; }

.iti__flag.iti__cv {
  height: 12px;
  background-position: -1159px 0px; }

.iti__flag.iti__cw {
  height: 14px;
  background-position: -1181px 0px; }

.iti__flag.iti__cx {
  height: 10px;
  background-position: -1203px 0px; }

.iti__flag.iti__cy {
  height: 14px;
  background-position: -1225px 0px; }

.iti__flag.iti__cz {
  height: 14px;
  background-position: -1247px 0px; }

.iti__flag.iti__de {
  height: 12px;
  background-position: -1269px 0px; }

.iti__flag.iti__dg {
  height: 10px;
  background-position: -1291px 0px; }

.iti__flag.iti__dj {
  height: 14px;
  background-position: -1313px 0px; }

.iti__flag.iti__dk {
  height: 15px;
  background-position: -1335px 0px; }

.iti__flag.iti__dm {
  height: 10px;
  background-position: -1357px 0px; }

.iti__flag.iti__do {
  height: 14px;
  background-position: -1379px 0px; }

.iti__flag.iti__dz {
  height: 14px;
  background-position: -1401px 0px; }

.iti__flag.iti__ea {
  height: 14px;
  background-position: -1423px 0px; }

.iti__flag.iti__ec {
  height: 14px;
  background-position: -1445px 0px; }

.iti__flag.iti__ee {
  height: 13px;
  background-position: -1467px 0px; }

.iti__flag.iti__eg {
  height: 14px;
  background-position: -1489px 0px; }

.iti__flag.iti__eh {
  height: 10px;
  background-position: -1511px 0px; }

.iti__flag.iti__er {
  height: 10px;
  background-position: -1533px 0px; }

.iti__flag.iti__es {
  height: 14px;
  background-position: -1555px 0px; }

.iti__flag.iti__et {
  height: 10px;
  background-position: -1577px 0px; }

.iti__flag.iti__eu {
  height: 14px;
  background-position: -1599px 0px; }

.iti__flag.iti__fi {
  height: 12px;
  background-position: -1621px 0px; }

.iti__flag.iti__fj {
  height: 10px;
  background-position: -1643px 0px; }

.iti__flag.iti__fk {
  height: 10px;
  background-position: -1665px 0px; }

.iti__flag.iti__fm {
  height: 11px;
  background-position: -1687px 0px; }

.iti__flag.iti__fo {
  height: 15px;
  background-position: -1709px 0px; }

.iti__flag.iti__fr {
  height: 14px;
  background-position: -1731px 0px; }

.iti__flag.iti__ga {
  height: 15px;
  background-position: -1753px 0px; }

.iti__flag.iti__gb {
  height: 10px;
  background-position: -1775px 0px; }

.iti__flag.iti__gd {
  height: 12px;
  background-position: -1797px 0px; }

.iti__flag.iti__ge {
  height: 14px;
  background-position: -1819px 0px; }

.iti__flag.iti__gf {
  height: 14px;
  background-position: -1841px 0px; }

.iti__flag.iti__gg {
  height: 14px;
  background-position: -1863px 0px; }

.iti__flag.iti__gh {
  height: 14px;
  background-position: -1885px 0px; }

.iti__flag.iti__gi {
  height: 10px;
  background-position: -1907px 0px; }

.iti__flag.iti__gl {
  height: 14px;
  background-position: -1929px 0px; }

.iti__flag.iti__gm {
  height: 14px;
  background-position: -1951px 0px; }

.iti__flag.iti__gn {
  height: 14px;
  background-position: -1973px 0px; }

.iti__flag.iti__gp {
  height: 14px;
  background-position: -1995px 0px; }

.iti__flag.iti__gq {
  height: 14px;
  background-position: -2017px 0px; }

.iti__flag.iti__gr {
  height: 14px;
  background-position: -2039px 0px; }

.iti__flag.iti__gs {
  height: 10px;
  background-position: -2061px 0px; }

.iti__flag.iti__gt {
  height: 13px;
  background-position: -2083px 0px; }

.iti__flag.iti__gu {
  height: 11px;
  background-position: -2105px 0px; }

.iti__flag.iti__gw {
  height: 10px;
  background-position: -2127px 0px; }

.iti__flag.iti__gy {
  height: 12px;
  background-position: -2149px 0px; }

.iti__flag.iti__hk {
  height: 14px;
  background-position: -2171px 0px; }

.iti__flag.iti__hm {
  height: 10px;
  background-position: -2193px 0px; }

.iti__flag.iti__hn {
  height: 10px;
  background-position: -2215px 0px; }

.iti__flag.iti__hr {
  height: 10px;
  background-position: -2237px 0px; }

.iti__flag.iti__ht {
  height: 12px;
  background-position: -2259px 0px; }

.iti__flag.iti__hu {
  height: 10px;
  background-position: -2281px 0px; }

.iti__flag.iti__ic {
  height: 14px;
  background-position: -2303px 0px; }

.iti__flag.iti__id {
  height: 14px;
  background-position: -2325px 0px; }

.iti__flag.iti__ie {
  height: 10px;
  background-position: -2347px 0px; }

.iti__flag.iti__il {
  height: 15px;
  background-position: -2369px 0px; }

.iti__flag.iti__im {
  height: 10px;
  background-position: -2391px 0px; }

.iti__flag.iti__in {
  height: 14px;
  background-position: -2413px 0px; }

.iti__flag.iti__io {
  height: 10px;
  background-position: -2435px 0px; }

.iti__flag.iti__iq {
  height: 14px;
  background-position: -2457px 0px; }

.iti__flag.iti__ir {
  height: 12px;
  background-position: -2479px 0px; }

.iti__flag.iti__is {
  height: 15px;
  background-position: -2501px 0px; }

.iti__flag.iti__it {
  height: 14px;
  background-position: -2523px 0px; }

.iti__flag.iti__je {
  height: 12px;
  background-position: -2545px 0px; }

.iti__flag.iti__jm {
  height: 10px;
  background-position: -2567px 0px; }

.iti__flag.iti__jo {
  height: 10px;
  background-position: -2589px 0px; }

.iti__flag.iti__jp {
  height: 14px;
  background-position: -2611px 0px; }

.iti__flag.iti__ke {
  height: 14px;
  background-position: -2633px 0px; }

.iti__flag.iti__kg {
  height: 12px;
  background-position: -2655px 0px; }

.iti__flag.iti__kh {
  height: 13px;
  background-position: -2677px 0px; }

.iti__flag.iti__ki {
  height: 10px;
  background-position: -2699px 0px; }

.iti__flag.iti__km {
  height: 12px;
  background-position: -2721px 0px; }

.iti__flag.iti__kn {
  height: 14px;
  background-position: -2743px 0px; }

.iti__flag.iti__kp {
  height: 10px;
  background-position: -2765px 0px; }

.iti__flag.iti__kr {
  height: 14px;
  background-position: -2787px 0px; }

.iti__flag.iti__kw {
  height: 10px;
  background-position: -2809px 0px; }

.iti__flag.iti__ky {
  height: 10px;
  background-position: -2831px 0px; }

.iti__flag.iti__kz {
  height: 10px;
  background-position: -2853px 0px; }

.iti__flag.iti__la {
  height: 14px;
  background-position: -2875px 0px; }

.iti__flag.iti__lb {
  height: 14px;
  background-position: -2897px 0px; }

.iti__flag.iti__lc {
  height: 10px;
  background-position: -2919px 0px; }

.iti__flag.iti__li {
  height: 12px;
  background-position: -2941px 0px; }

.iti__flag.iti__lk {
  height: 10px;
  background-position: -2963px 0px; }

.iti__flag.iti__lr {
  height: 11px;
  background-position: -2985px 0px; }

.iti__flag.iti__ls {
  height: 14px;
  background-position: -3007px 0px; }

.iti__flag.iti__lt {
  height: 12px;
  background-position: -3029px 0px; }

.iti__flag.iti__lu {
  height: 12px;
  background-position: -3051px 0px; }

.iti__flag.iti__lv {
  height: 10px;
  background-position: -3073px 0px; }

.iti__flag.iti__ly {
  height: 10px;
  background-position: -3095px 0px; }

.iti__flag.iti__ma {
  height: 14px;
  background-position: -3117px 0px; }

.iti__flag.iti__mc {
  height: 15px;
  background-position: -3139px 0px; }

.iti__flag.iti__md {
  height: 10px;
  background-position: -3160px 0px; }

.iti__flag.iti__me {
  height: 10px;
  background-position: -3182px 0px; }

.iti__flag.iti__mf {
  height: 14px;
  background-position: -3204px 0px; }

.iti__flag.iti__mg {
  height: 14px;
  background-position: -3226px 0px; }

.iti__flag.iti__mh {
  height: 11px;
  background-position: -3248px 0px; }

.iti__flag.iti__mk {
  height: 10px;
  background-position: -3270px 0px; }

.iti__flag.iti__ml {
  height: 14px;
  background-position: -3292px 0px; }

.iti__flag.iti__mm {
  height: 14px;
  background-position: -3314px 0px; }

.iti__flag.iti__mn {
  height: 10px;
  background-position: -3336px 0px; }

.iti__flag.iti__mo {
  height: 14px;
  background-position: -3358px 0px; }

.iti__flag.iti__mp {
  height: 10px;
  background-position: -3380px 0px; }

.iti__flag.iti__mq {
  height: 14px;
  background-position: -3402px 0px; }

.iti__flag.iti__mr {
  height: 14px;
  background-position: -3424px 0px; }

.iti__flag.iti__ms {
  height: 10px;
  background-position: -3446px 0px; }

.iti__flag.iti__mt {
  height: 14px;
  background-position: -3468px 0px; }

.iti__flag.iti__mu {
  height: 14px;
  background-position: -3490px 0px; }

.iti__flag.iti__mv {
  height: 14px;
  background-position: -3512px 0px; }

.iti__flag.iti__mw {
  height: 14px;
  background-position: -3534px 0px; }

.iti__flag.iti__mx {
  height: 12px;
  background-position: -3556px 0px; }

.iti__flag.iti__my {
  height: 10px;
  background-position: -3578px 0px; }

.iti__flag.iti__mz {
  height: 14px;
  background-position: -3600px 0px; }

.iti__flag.iti__na {
  height: 14px;
  background-position: -3622px 0px; }

.iti__flag.iti__nc {
  height: 10px;
  background-position: -3644px 0px; }

.iti__flag.iti__ne {
  height: 15px;
  background-position: -3666px 0px; }

.iti__flag.iti__nf {
  height: 10px;
  background-position: -3686px 0px; }

.iti__flag.iti__ng {
  height: 10px;
  background-position: -3708px 0px; }

.iti__flag.iti__ni {
  height: 12px;
  background-position: -3730px 0px; }

.iti__flag.iti__nl {
  height: 14px;
  background-position: -3752px 0px; }

.iti__flag.iti__no {
  height: 15px;
  background-position: -3774px 0px; }

.iti__flag.iti__np {
  height: 15px;
  background-position: -3796px 0px; }

.iti__flag.iti__nr {
  height: 10px;
  background-position: -3811px 0px; }

.iti__flag.iti__nu {
  height: 10px;
  background-position: -3833px 0px; }

.iti__flag.iti__nz {
  height: 10px;
  background-position: -3855px 0px; }

.iti__flag.iti__om {
  height: 10px;
  background-position: -3877px 0px; }

.iti__flag.iti__pa {
  height: 14px;
  background-position: -3899px 0px; }

.iti__flag.iti__pe {
  height: 14px;
  background-position: -3921px 0px; }

.iti__flag.iti__pf {
  height: 14px;
  background-position: -3943px 0px; }

.iti__flag.iti__pg {
  height: 15px;
  background-position: -3965px 0px; }

.iti__flag.iti__ph {
  height: 10px;
  background-position: -3987px 0px; }

.iti__flag.iti__pk {
  height: 14px;
  background-position: -4009px 0px; }

.iti__flag.iti__pl {
  height: 13px;
  background-position: -4031px 0px; }

.iti__flag.iti__pm {
  height: 14px;
  background-position: -4053px 0px; }

.iti__flag.iti__pn {
  height: 10px;
  background-position: -4075px 0px; }

.iti__flag.iti__pr {
  height: 14px;
  background-position: -4097px 0px; }

.iti__flag.iti__ps {
  height: 10px;
  background-position: -4119px 0px; }

.iti__flag.iti__pt {
  height: 14px;
  background-position: -4141px 0px; }

.iti__flag.iti__pw {
  height: 13px;
  background-position: -4163px 0px; }

.iti__flag.iti__py {
  height: 11px;
  background-position: -4185px 0px; }

.iti__flag.iti__qa {
  height: 8px;
  background-position: -4207px 0px; }

.iti__flag.iti__re {
  height: 14px;
  background-position: -4229px 0px; }

.iti__flag.iti__ro {
  height: 14px;
  background-position: -4251px 0px; }

.iti__flag.iti__rs {
  height: 14px;
  background-position: -4273px 0px; }

.iti__flag.iti__ru {
  height: 14px;
  background-position: -4295px 0px; }

.iti__flag.iti__rw {
  height: 14px;
  background-position: -4317px 0px; }

.iti__flag.iti__sa {
  height: 14px;
  background-position: -4339px 0px; }

.iti__flag.iti__sb {
  height: 10px;
  background-position: -4361px 0px; }

.iti__flag.iti__sc {
  height: 10px;
  background-position: -4383px 0px; }

.iti__flag.iti__sd {
  height: 10px;
  background-position: -4405px 0px; }

.iti__flag.iti__se {
  height: 13px;
  background-position: -4427px 0px; }

.iti__flag.iti__sg {
  height: 14px;
  background-position: -4449px 0px; }

.iti__flag.iti__sh {
  height: 10px;
  background-position: -4471px 0px; }

.iti__flag.iti__si {
  height: 10px;
  background-position: -4493px 0px; }

.iti__flag.iti__sj {
  height: 15px;
  background-position: -4515px 0px; }

.iti__flag.iti__sk {
  height: 14px;
  background-position: -4537px 0px; }

.iti__flag.iti__sl {
  height: 14px;
  background-position: -4559px 0px; }

.iti__flag.iti__sm {
  height: 15px;
  background-position: -4581px 0px; }

.iti__flag.iti__sn {
  height: 14px;
  background-position: -4603px 0px; }

.iti__flag.iti__so {
  height: 14px;
  background-position: -4625px 0px; }

.iti__flag.iti__sr {
  height: 14px;
  background-position: -4647px 0px; }

.iti__flag.iti__ss {
  height: 10px;
  background-position: -4669px 0px; }

.iti__flag.iti__st {
  height: 10px;
  background-position: -4691px 0px; }

.iti__flag.iti__sv {
  height: 12px;
  background-position: -4713px 0px; }

.iti__flag.iti__sx {
  height: 14px;
  background-position: -4735px 0px; }

.iti__flag.iti__sy {
  height: 14px;
  background-position: -4757px 0px; }

.iti__flag.iti__sz {
  height: 14px;
  background-position: -4779px 0px; }

.iti__flag.iti__ta {
  height: 10px;
  background-position: -4801px 0px; }

.iti__flag.iti__tc {
  height: 10px;
  background-position: -4823px 0px; }

.iti__flag.iti__td {
  height: 14px;
  background-position: -4845px 0px; }

.iti__flag.iti__tf {
  height: 14px;
  background-position: -4867px 0px; }

.iti__flag.iti__tg {
  height: 13px;
  background-position: -4889px 0px; }

.iti__flag.iti__th {
  height: 14px;
  background-position: -4911px 0px; }

.iti__flag.iti__tj {
  height: 10px;
  background-position: -4933px 0px; }

.iti__flag.iti__tk {
  height: 10px;
  background-position: -4955px 0px; }

.iti__flag.iti__tl {
  height: 10px;
  background-position: -4977px 0px; }

.iti__flag.iti__tm {
  height: 14px;
  background-position: -4999px 0px; }

.iti__flag.iti__tn {
  height: 14px;
  background-position: -5021px 0px; }

.iti__flag.iti__to {
  height: 10px;
  background-position: -5043px 0px; }

.iti__flag.iti__tr {
  height: 14px;
  background-position: -5065px 0px; }

.iti__flag.iti__tt {
  height: 12px;
  background-position: -5087px 0px; }

.iti__flag.iti__tv {
  height: 10px;
  background-position: -5109px 0px; }

.iti__flag.iti__tw {
  height: 14px;
  background-position: -5131px 0px; }

.iti__flag.iti__tz {
  height: 14px;
  background-position: -5153px 0px; }

.iti__flag.iti__ua {
  height: 14px;
  background-position: -5175px 0px; }

.iti__flag.iti__ug {
  height: 14px;
  background-position: -5197px 0px; }

.iti__flag.iti__um {
  height: 11px;
  background-position: -5219px 0px; }

.iti__flag.iti__un {
  height: 14px;
  background-position: -5241px 0px; }

.iti__flag.iti__us {
  height: 11px;
  background-position: -5263px 0px; }

.iti__flag.iti__uy {
  height: 14px;
  background-position: -5285px 0px; }

.iti__flag.iti__uz {
  height: 10px;
  background-position: -5307px 0px; }

.iti__flag.iti__va {
  height: 15px;
  background-position: -5329px 0px; }

.iti__flag.iti__vc {
  height: 14px;
  background-position: -5346px 0px; }

.iti__flag.iti__ve {
  height: 14px;
  background-position: -5368px 0px; }

.iti__flag.iti__vg {
  height: 10px;
  background-position: -5390px 0px; }

.iti__flag.iti__vi {
  height: 14px;
  background-position: -5412px 0px; }

.iti__flag.iti__vn {
  height: 14px;
  background-position: -5434px 0px; }

.iti__flag.iti__vu {
  height: 12px;
  background-position: -5456px 0px; }

.iti__flag.iti__wf {
  height: 14px;
  background-position: -5478px 0px; }

.iti__flag.iti__ws {
  height: 10px;
  background-position: -5500px 0px; }

.iti__flag.iti__xk {
  height: 15px;
  background-position: -5522px 0px; }

.iti__flag.iti__ye {
  height: 14px;
  background-position: -5544px 0px; }

.iti__flag.iti__yt {
  height: 14px;
  background-position: -5566px 0px; }

.iti__flag.iti__za {
  height: 14px;
  background-position: -5588px 0px; }

.iti__flag.iti__zm {
  height: 14px;
  background-position: -5610px 0px; }

.iti__flag.iti__zw {
  height: 10px;
  background-position: -5632px 0px; }

.iti__flag {
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("../images/flags.png");
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0; }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("../images/flags@2x.png"); } }

.iti__flag.iti__np {
  background-color: transparent; }

@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter-Regular.woff2") format("woff2"), url("../fonts/Inter-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter-Bold.woff2") format("woff2"), url("../fonts/Inter-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter-BoldItalic.woff2") format("woff2"), url("../fonts/Inter-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter-Black.woff2") format("woff2"), url("../fonts/Inter-Black.woff") format("woff");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'PodiumSharp211';
  src: url("../fonts/PODIUMSharp-211.woff2") format("woff2"), url("../fonts/PODIUMSharp-211.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'PodiumSharp211';
  src: url("../fonts/PodiumSharp211Italic.woff2") format("woff2"), url("../fonts/PodiumSharp211Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'PodiumSharp212';
  src: url("../fonts/PodiumSharp212Regular.woff2") format("woff2"), url("../fonts/PodiumSharp212Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'PodiumSharp212';
  src: url("../fonts/PodiumSharp212Italic.woff2") format("woff2"), url("../fonts/PodiumSharp212Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'BarlowCondensed';
  src: url("../fonts/BarlowCondensed-ExtraBold.woff2") format("woff2"), url("../fonts/BarlowCondensed-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'BarlowSemiCondensed';
  src: url("../fonts/BarlowSemiCondensed-Black.woff2") format("woff2"), url("../fonts/BarlowSemiCondensed-Black.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Futura';
  src: url("../fonts/Futura-Medium-Italic.woff2") format("woff2"), url("../fonts/Futura-Medium-Italic.woff") format("woff");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Futura Condensed';
  src: url("../fonts/Futura-Condensed-Extra-Bold.woff2") format("woff2"), url("../fonts/Futura-Condensed-Extra-Bold.woff") format("woff");
  font-weight: 400; }

:root {
  font-size: 50%; }
  @media (min-width: 810px) {
    :root {
      font-size: 56.25%; } }
  @media (min-width: 992px) {
    :root {
      font-size: 62.5%; } }

html, body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 2.5rem;
  line-height: 1.5;
  font-family: "Inter", sans-serif; }
  @media (min-width: 1200px) {
    html, body {
      font-size: 2.9rem; } }

p {
  margin-bottom: 3rem; }
  p:last-child {
    margin-bottom: 0; }
  @media (min-width: 810px) {
    p {
      margin-bottom: 4rem; } }
  p em {
    font-style: normal;
    font-weight: 400;
    background: #FEF431;
    padding: 0px 4px; }
  p.letterhead {
    font-size: 16px; }
    p.letterhead:after {
      content: '';
      width: 130px;
      height: 6px;
      background: #000000;
      display: block;
      margin: 4rem 0; }
      @media (min-width: 810px) {
        p.letterhead:after {
          margin: 4.5rem 0;
          height: 8px; } }
  p.lead {
    font-weight: 700;
    font-size: 3rem;
    line-height: 1.3; }
    @media (min-width: 1200px) {
      p.lead {
        font-size: 3.7rem; } }
  p a {
    transition: color 300ms ease; }
    p a:hover {
      color: #D94632; }

.font-normal {
  font-style: normal !important; }

h1, .h1, .h1, h2, .h2, .h2, h3, .h3, .h3, h4, .h4, .h4, h5, .h5, .h5, h6, .h6, .h6 {
  line-height: 1.3;
  margin-bottom: 4.5rem;
  font-family: "PodiumSharp211", sans-serif;
  font-weight: normal;
  text-transform: uppercase; }

h1, .h1, .h1 {
  font-size: 12.6vw; }

h2, .h2, .h2 {
  font-size: 13vw; }

h1, .h1, .h1, h2, .h2, .h2 {
  line-height: .86;
  letter-spacing: -0.01em;
  text-transform: uppercase; }
  h1 .h3, .h1 .h3, .h1 .h3, h2 .h3, .h2 .h3, .h2 .h3 {
    margin-bottom: 1rem;
    display: block; }
  @media (max-width: 399.98px) {
    h1.bigonmobile, .bigonmobile.h1, .h1.bigonmobile, h2.bigonmobile, .bigonmobile.h2, .h2.bigonmobile {
      font-size: 14.5vw; } }
  @media (min-width: 680px) {
    h1, .h1, .h1, h2, .h2, .h2 {
      font-size: 10vw; } }
  @media (min-width: 810px) {
    h1, .h1, .h1, h2, .h2, .h2 {
      font-size: 10vw; } }
  @media (min-width: 992px) {
    h1, .h1, .h1, h2, .h2, .h2 {
      font-size: 10vw; } }
  @media (min-width: 1200px) {
    h1, .h1, .h1, h2, .h2, .h2 {
      font-size: 11vw; } }
  @media screen and (min-width: 1400px) {
    h1, .h1, .h1, h2, .h2, .h2 {
      font-size: 16.1rem; } }
  @media screen and (min-width: 1400px) and (max-height: 1000px) {
    h1, .h1, .h1, h2, .h2, .h2 {
      font-size: 13rem; } }

h3, .h3, .h3 {
  font-size: 3rem;
  letter-spacing: 0;
  font-family: "PodiumSharp212", sans-serif; }
  @media (min-width: 680px) {
    h3, .h3, .h3 {
      font-size: 3.5rem; } }
  @media (min-width: 1200px) {
    h3, .h3, .h3 {
      font-size: 4.24rem; } }

h4, .h4, .h4 {
  font-size: 2.4rem; }

h5, .h5, .h5 {
  font-size: 2rem; }

h6, .h6, .h6 {
  font-size: 1.8rem; }

.eyebrow {
  text-transform: uppercase;
  font-family: "Inter", sans-serif;
  font-style: italic;
  font-weight: 700;
  font-size: 2rem;
  line-height: .95;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFF;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1); }
  @media screen and (max-width: 400px) {
    .eyebrow {
      font-size: 1.65rem;
      letter-spacing: 0.01em; } }
  @media (min-width: 810px) {
    .eyebrow {
      font-size: 2.4rem; } }

.subheadline {
  font-size: 2.2rem; }

.quotes:before, .quotes:after {
  font-family: "Inter", sans-serif;
  font-weight: 800;
  font-size: 80%;
  vertical-align: top;
  line-height: 1;
  position: relative;
  top: 0px; }

.quotes:before {
  content: '“';
  left: 5px; }

.quotes:after {
  content: '”';
  right: 0px; }

@media (min-width: 992px) {
  .quotes:before, .quotes:after {
    top: -5px; }
  .quotes:before {
    content: '“';
    left: 10px; }
  .quotes:after {
    content: '”';
    right: -10px; } }

@media (min-width: 1200px) {
  .quotes:before, .quotes:after {
    top: -10px; }
  .quotes:before {
    content: '“';
    left: 20px; }
  .quotes:after {
    content: '”';
    right: 0px; } }

.quotes--alt {
  font-style: normal; }
  .quotes--alt:before, .quotes--alt:after {
    left: 0;
    content: '"';
    font-family: 'Futura Condensed', sans-serif;
    top: 5px; }

.ssb {
  font-family: 'Futura', sans-serif;
  position: relative; }
  .ssb--inter {
    font-family: "Inter", sans-serif; }
    h2 .ssb--inter, .h2 .ssb--inter {
      line-height: .8;
      display: inline-block;
      position: relative;
      left: -9px;
      top: -8px; }
  .ssb--cond {
    font-style: normal;
    font-family: 'Futura Condensed', sans-serif; }
  .ssb--i {
    font-style: italic; }
  .ssb--m {
    font-weight: 600; }

i {
  font-style: normal; }

.summit-countdown {
  font-size: 2rem;
  font-weight: 600;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }
  .summit-countdown i {
    font-style: normal;
    display: block; }
    @media (min-width: 680px) {
      .summit-countdown i {
        display: inline; } }

section {
  padding: 60px 0px;
  position: relative;
  overflow: hidden; }
  @media (min-width: 810px) {
    section {
      padding: 80px 0px; } }
  @media (min-width: 992px) {
    section {
      padding: 120px 0px; } }
  section .container {
    z-index: 1;
    position: relative; }

.background-image-container {
  overflow: hidden;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; }
  .background-image-container.mh-1 {
    max-height: 87vw; }
  .background-image-container.mh-2 {
    top: auto;
    bottom: 0;
    max-height: 87vw; }
  .background-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none; }
  .background-image-container.semi-transparent-bg-dark {
    background: #000; }
    .background-image-container.semi-transparent-bg-dark img {
      opacity: 0.6; }
  .background-image-container.semi-transparent-bg-light {
    background: #fff; }
    .background-image-container.semi-transparent-bg-light img {
      opacity: 0.4; }

.video-preview-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden; }
  .video-preview-container iframe,
  .video-preview-container object,
  .video-preview-container embed,
  .video-preview-container img.poster,
  .video-preview-container .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .video-preview-container .play-button {
    width: 100px;
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 40px);
    padding: 0;
    margin: 0;
    border: none;
    appearance: none;
    background: none;
    z-index: 5;
    transition: .8s ease; }
    .video-preview-container .play-button:hover {
      transform: scale(1.1);
      cursor: pointer; }
    .video-preview-container .play-button:focus {
      outline: 0; }

img {
  width: 100%; }

.max-width-image {
  width: 100%;
  max-width: 300px; }
  @media (min-width: 810px) {
    .max-width-image {
      max-width: 400px; } }
  @media (min-width: 992px) {
    .max-width-image {
      max-width: 100%; } }

.bg-dark {
  background: linear-gradient(180deg, #232323 0%, #000000 100%); }

.color-primary {
  color: #D94632; }

.color-secondary {
  color: #333; }

.grecaptcha-badge {
  visibility: hidden; }

.view-animation {
  opacity: 0; }

.animated {
  opacity: 1;
  transition: 1s; }

.fade-up.animated,
.fade-down.animated,
.fade-left.animated,
.fade-right.animated {
  transform: translate3d(0, 0, 0); }

.scale-up.animated,
.scale-down.animated {
  transform: scale(1); }

.bounce-left.animated,
.bounce-right.animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.fade-up {
  transform: translate3d(0, 100px, 0); }

.fade-down {
  transform: translate3d(0, -100px, 0); }

.fade-left {
  transform: translate3d(100px, 0, 0); }

.fade-right {
  transform: translate3d(-100px, 0, 0); }

.scale-up {
  transform: scale(0.5); }

.scale-down {
  transform: scale(1.5); }

.bounce-left.animated {
  transform: translate3d(140px, -200px, -70px);
  animation-name: BounceLeft; }

.bounce-right.animated {
  transform: translate3d(-140px, -200px, -70px);
  animation-name: BounceRight; }

.delay-0 {
  animation-delay: 0s;
  transition-delay: 0s; }

.delay-1 {
  animation-delay: .25s;
  transition-delay: .25s; }

.delay-2 {
  animation-delay: .5s;
  transition-delay: .5s; }

.delay-3 {
  animation-delay: .75s;
  transition-delay: .75s; }

.delay-4 {
  animation-delay: 1s;
  transition-delay: 1s; }

.delay-5 {
  animation-delay: 1.25s;
  transition-delay: 1.25s; }

.delay-6 {
  animation-delay: 1.5s;
  transition-delay: 1.5s; }

@keyframes BounceLeft {
  0% {
    transform: translate3d(140px, -200px, -70px); }
  10% {
    transform: translate3d(130px, -100px, -70px); }
  20% {
    transform: translate3d(120px, 0, -60px); }
  30% {
    transform: translate3d(110px, -45px, -60px); }
  40% {
    transform: translate3d(100px, -90px, -50px); }
  50% {
    transform: translate3d(90px, -45px, -50px); }
  60% {
    transform: translate3d(80px, 0, -40px); }
  65% {
    transform: translate3d(70px, -30px, -40px); }
  70% {
    transform: translate3d(60px, -60px, -30px); }
  75% {
    transform: translate3d(50px, -30px, -30px); }
  80% {
    transform: translate3d(40px, 0, -20px); }
  85% {
    transform: translate3d(30px, -20px, -20px); }
  90% {
    transform: translate3d(20px, -40px, -10px); }
  95% {
    transform: translate3d(10px, -20px, -10px); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes BounceRight {
  0% {
    transform: translate3d(-140px, -200px, -70px); }
  10% {
    transform: translate3d(-130px, -100px, -70px); }
  20% {
    transform: translate3d(-120px, 0, -60px); }
  30% {
    transform: translate3d(-110px, -45px, -60px); }
  40% {
    transform: translate3d(-100px, -90px, -50px); }
  50% {
    transform: translate3d(-90px, -45px, -50px); }
  60% {
    transform: translate3d(-80px, 0, -40px); }
  65% {
    transform: translate3d(-70px, -30px, -40px); }
  70% {
    transform: translate3d(-60px, -60px, -30px); }
  75% {
    transform: translate3d(-50px, -30px, -30px); }
  80% {
    transform: translate3d(-40px, 0, -20px); }
  85% {
    transform: translate3d(-30px, -20px, -20px); }
  90% {
    transform: translate3d(-20px, -40px, -10px); }
  95% {
    transform: translate3d(-10px, -20px, -10px); }
  100% {
    transform: translate3d(0, 0, 0); } }

.flashing {
  animation: flashing 2.2s infinite; }

@keyframes flashing {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  to {
    opacity: 1; } }

.marquee {
  animation: marquee 30s linear infinite;
  white-space: nowrap; }
  .marquee span {
    margin-left: 2rem;
    margin-right: 2rem; }
  .marquee i {
    font-style: normal;
    font-weight: normal; }

@keyframes marquee {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(-100%); } }

@keyframes smallPulse {
  from {
    transform: scale(0.75);
    opacity: 1; }
  95%, 100% {
    transform: scale(2);
    opacity: 0; } }

@keyframes mediumPulse {
  from {
    transform: scale(0.75);
    opacity: 1; }
  95%, 100% {
    transform: scale(3);
    opacity: 0; } }

header {
  padding: 15px;
  background-color: transparent; }
  header.scrolled {
    box-shadow: -3px -3px 6px 8px rgba(0, 0, 0, 0.05);
    background-color: #fff;
    transition: 0.2s ease-in-out; }

.header-logo img {
  transition: 0.2s ease-in-out;
  width: 200px; }
  @media (min-width: 810px) {
    .header-logo img {
      width: 280px; } }
  @media (min-width: 810px) {
    .scrolled .header-logo img {
      width: 200px; } }

.header-phone {
  line-height: 1.2; }
  .header-phone .phone-icon img,
  .header-phone .phone-icon svg {
    max-width: 35px;
    width: 100%; }
  .header-phone em {
    font-style: normal;
    font-weight: bold; }

footer {
  background: #FFF;
  padding: 35px 0; }
  footer p {
    font-size: 13px;
    text-align: center;
    color: #000000;
    max-width: 1140px;
    margin: 0 auto 2rem; }
    footer p a {
      color: inherit;
      text-decoration: none; }

.btn,
button,
input[type=submit],
input[type=button] {
  background: #D94632;
  color: #fff;
  font-family: 'BarlowCondensed', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 4.8rem;
  letter-spacing: -0.02em;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
  padding: 13px 10px 15px;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
  line-height: .9;
  text-transform: uppercase;
  white-space: normal;
  transition: transform 300ms ease;
  outline: none;
  border: none;
  box-shadow: none; }
  .btn:hover, .btn:focus, .btn:active,
  button:hover,
  button:focus,
  button:active,
  input[type=submit]:hover,
  input[type=submit]:focus,
  input[type=submit]:active,
  input[type=button]:hover,
  input[type=button]:focus,
  input[type=button]:active {
    background: #D94632;
    color: #fff;
    box-shadow: none !important;
    outline: none;
    border: none;
    transform: scale(1.02); }
  .btn span,
  button span,
  input[type=submit] span,
  input[type=button] span {
    display: block;
    margin: 8px 0 0; }
  .btn i, .btn span,
  button i,
  button span,
  input[type=submit] i,
  input[type=submit] span,
  input[type=button] i,
  input[type=button] span {
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: .95;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #7A170A;
    font-family: "Inter", sans-serif;
    text-shadow: none;
    pointer-events: none; }
  .modal .btn, .modal
  button, .modal
  input[type=submit], .modal
  input[type=button] {
    padding: 12px 10px 16px; }
  @media (min-width: 680px) {
    .section-hero .btn, .section-hero
    button, .section-hero
    input[type=submit], .section-hero
    input[type=button] {
      padding: 17px 10px 21px; }
      .section-hero .btn i, .section-hero .btn span, .section-hero
      button i, .section-hero
      button span, .section-hero
      input[type=submit] i, .section-hero
      input[type=submit] span, .section-hero
      input[type=button] i, .section-hero
      input[type=button] span {
        font-size: 1.5rem; } }
  @media (max-width: 679.98px) {
    .btn,
    button,
    input[type=submit],
    input[type=button] {
      padding: 18px 15px 19px;
      font-size: 4.1rem; }
      .btn i, .btn span,
      button i,
      button span,
      input[type=submit] i,
      input[type=submit] span,
      input[type=button] i,
      input[type=button] span {
        font-size: 1.4rem;
        line-height: 1.1;
        letter-spacing: 0.01em; } }

.btn-section {
  position: relative;
  z-index: 5; }
  .btn-section p {
    width: 90%;
    margin: 0 auto; }

ul {
  list-style: none;
  margin: 4rem 0 4rem;
  padding: 0; }
  ul li {
    margin: 0 0 4rem;
    position: relative; }
  ul.cash li {
    padding-left: 42px; }
    ul.cash li:before {
      content: '';
      width: 37px;
      height: 37px;
      background: url("../images/icon-moneybag.png") no-repeat center/contain;
      position: absolute;
      top: 0px;
      left: -3px; }
    @media (min-width: 1200px) {
      ul.cash li {
        padding-left: 55px; }
        ul.cash li:before {
          width: 52px;
          height: 49px; } }
  ul.red-dot li {
    padding-left: 40px; }
    ul.red-dot li:before {
      content: '';
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #D94632;
      display: block;
      position: absolute;
      left: 4px;
      top: 12px; }
    ul.red-dot li:after {
      content: '';
      position: absolute;
      top: 4px;
      left: -4px;
      background: rgba(217, 70, 50, 0.2);
      width: 34px;
      height: 34px;
      border-radius: 50%; }
    @media (min-width: 810px) {
      ul.red-dot li:before {
        left: 0;
        top: 12px; }
      ul.red-dot li:after {
        top: 4px;
        left: -8px; } }

form input[type=text],
form input[type=email],
form input[type=tel],
form textarea,
form select {
  width: 100%;
  padding: 0px 20px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  border: none;
  margin: 0 0 10px;
  border-radius: 5px;
  font-size: 2rem;
  line-height: 1;
  height: 50px; }
  @media (min-width: 810px) {
    form input[type=text],
    form input[type=email],
    form input[type=tel],
    form textarea,
    form select {
      padding: 0px 20px;
      height: 65px; } }
  form input[type=text]:-internal-autofill-selected,
  form input[type=email]:-internal-autofill-selected,
  form input[type=tel]:-internal-autofill-selected,
  form textarea:-internal-autofill-selected,
  form select:-internal-autofill-selected {
    background-color: #fff !important;
    background-image: none !important;
    color: -internal-light-dark-color(black, white) !important; }

form textarea {
  height: 210px !important; }

form select {
  cursor: pointer;
  appearance: none;
  background: #FFF url("../images/arrow-down.png") no-repeat 96% 50%/16px 10px; }

form .slc-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

form .slc-col {
  width: 100%; }
  @media (min-width: 680px) {
    form .slc-col {
      width: calc(50% - 5px); } }

form .iti {
  margin: 0 0 10px; }

form label.error,
form .field-error {
  display: block;
  margin-bottom: 10px;
  margin-top: 0px;
  color: red;
  font-size: 1.5rem;
  width: 100%;
  text-align: left; }

form .form-messages {
  margin: 15px 0 0;
  font-size: 1.6rem;
  text-align: center; }
  form .form-messages.success {
    color: green; }
  form .form-messages.error {
    color: red; }

.ribbon {
  position: relative;
  display: table;
  margin-left: auto;
  margin-right: auto;
  color: #FFF; }
  .ribbon:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background: url("../images/ribbon-large.png") no-repeat center/100%; }
  .ribbon--500 {
    transform: rotate(-1.8deg);
    margin-top: -5px; }
    .ribbon--500:before {
      background: url("../images/ribbon-xl.png") no-repeat center/100%; }
    .ribbon--500 span {
      padding: 10px 17px 3px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2); }
    @media (min-width: 1200px) {
      .ribbon--500 {
        margin-top: -10px; }
        .ribbon--500 span {
          padding: 14px 29px 0px; } }
  .ribbon--hero {
    transform: rotate(-2.85deg);
    margin-top: -20px; }
    .ribbon--hero:before {
      background: url("../images/ribbon-hero.png") no-repeat center/100%; }
    .ribbon--hero span {
      padding: 20px 30px 20px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2); }
    @media (min-width: 680px) {
      .ribbon--hero span {
        padding: 20px 40px 20px; } }
    @media (min-width: 992px) {
      .ribbon--hero {
        margin-top: -20px; }
        .ribbon--hero span {
          padding: 20px 50px 20px; } }
    @media (min-width: 1200px) {
      .ribbon--hero {
        margin-top: -28px; }
        .ribbon--hero span {
          padding: 20px 75px 20px; } }
    @media screen and (min-width: 1400px) and (max-height: 1000px) {
      .ribbon--hero {
        margin-top: -20px; }
        .ribbon--hero span {
          padding: 20px 60px 20px; } }
  .ribbon--listen {
    transform: rotate(-2.48deg);
    margin: 0 auto -12px; }
    .ribbon--listen:before {
      background: url("../images/ribbon-large.png") no-repeat center/100%; }
    .ribbon--listen span {
      text-shadow: 0px 4.615px 4.615px rgba(0, 0, 0, 0.1);
      font-size: 2.8rem;
      font-family: "PodiumSharp212", sans-serif;
      letter-spacing: 0;
      font-style: italic;
      padding: 20px 35px 20px; }
    @media (min-width: 810px) {
      .ribbon--listen {
        margin: 0 auto -14px; }
        .ribbon--listen span {
          font-size: 3rem;
          padding: 20px 50px 20px; } }
    @media (min-width: 1200px) {
      .ribbon--listen span {
        font-size: 4.26rem;
        padding: 27px 70px 30px; } }
  .ribbon--reveal {
    margin: -5px auto 0;
    transform: rotate(-2.5deg); }
    .ribbon--reveal:before {
      background: url("../images/ribbon-med.png") no-repeat center/100%; }
    .ribbon--reveal span {
      font-size: 6rem;
      font-family: "PodiumSharp211", sans-serif;
      letter-spacing: 0;
      font-style: italic;
      padding: 15px 30px 15px;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
      transform: rotate(-1.3deg); }
    @media (min-width: 400px) {
      .ribbon--reveal span {
        font-size: 7rem; } }
    @media (min-width: 680px) {
      .ribbon--reveal {
        margin: -8px auto 0; }
        .ribbon--reveal span {
          font-size: 8rem;
          padding: 15px 45px 15px; } }
    @media (min-width: 810px) {
      .ribbon--reveal {
        margin: -12px auto 0; }
        .ribbon--reveal span {
          font-size: 10rem;
          padding: 15px 55px 15px; } }
    @media (min-width: 1200px) {
      .ribbon--reveal {
        margin: -16px auto 0; }
        .ribbon--reveal span {
          font-size: 11.8rem;
          padding: 20px 65px 22px; } }
  .ribbon--attend, .ribbon--register, .ribbon--bonus {
    transform: rotate(-1.5deg);
    margin-top: -25px; }
    .ribbon--attend:before, .ribbon--register:before, .ribbon--bonus:before {
      background: url("../images/ribbon-med.png") no-repeat center/100%; }
    .ribbon--attend span, .ribbon--register span, .ribbon--bonus span {
      padding: 27px 18px 30px;
      transform: rotate(-1.4deg);
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2); }
    @media (min-width: 992px) {
      .ribbon--attend, .ribbon--register, .ribbon--bonus {
        margin-top: -35px; }
        .ribbon--attend span, .ribbon--register span, .ribbon--bonus span {
          padding: 32px 30px 35px; } }
  .ribbon--register:before {
    background: url("../images/ribbon-register.png") no-repeat center/100%; }
  @media (min-width: 992px) {
    .ribbon--register span {
      padding: 32px 25px 35px; } }
  .ribbon--bonus {
    margin-top: -17px; }
    .ribbon--bonus:before {
      background: url("../images/ribbon-bonus.png") no-repeat center/100%; }
    .ribbon--bonus span {
      padding: 22px 12px 25px; }
    @media (min-width: 992px) {
      .ribbon--bonus {
        margin-top: -32px; }
        .ribbon--bonus span {
          padding: 32px 22px 35px; } }
  .ribbon span {
    line-height: .88;
    z-index: 1;
    position: relative;
    display: table; }

.loop {
  position: relative;
  display: table;
  margin-left: auto;
  margin-right: auto; }
  .loop--inline {
    display: inline-block; }
  .loop span {
    position: relative;
    z-index: 1; }
  .loop--red:after {
    content: '';
    height: 110%;
    width: 110%;
    left: -30px;
    top: -7px;
    position: absolute;
    transform: rotate(-1.44deg);
    z-index: 2;
    background: url("../../src/svg/ribbon-line-red.svg") no-repeat center/contain; }
  .loop--red span {
    display: table; }
  @media (min-width: 1200px) {
    .loop--red:after {
      height: 116%;
      width: 114%;
      left: -63px;
      top: -16px; } }
  .loop--hero:after {
    content: '';
    height: 97%;
    width: 97%;
    left: 2%;
    top: 0%;
    position: absolute;
    z-index: 2;
    transform: rotate(2.85deg);
    background: url("../images/line-hero.png") no-repeat center/contain; }
  .loop--hero span {
    display: table; }
  @media (min-width: 810px) {
    .loop--hero:after {
      height: 106%;
      left: -2%;
      top: -5%;
      width: 106%; } }
  @media (min-width: 1200px) {
    .loop--hero:after {
      left: -7%;
      top: -8%;
      width: 116%;
      height: 116%; } }
  @media screen and (min-width: 1400px) and (max-height: 1000px) {
    .loop--hero:after {
      height: 106%;
      left: -2%;
      top: -5%;
      width: 106%; } }
  .loop--black:after {
    content: '';
    height: 103%;
    width: 100%;
    left: -7px;
    top: -3px;
    position: absolute;
    z-index: 2;
    transform: rotate(-1.11deg);
    background: url("../../src/svg/ribbon-line-black.svg") no-repeat center/contain; }
  .loop--black span {
    display: table; }
  @media (min-width: 992px) {
    .loop--black:after {
      left: -13px;
      top: -7px; } }

.js-modal-active {
  overflow-y: hidden; }
  .js-modal-active.js-modal-windows .site-content > section {
    padding-right: 15px; }
  .js-modal-active.js-modal-windows .site-header,
  .js-modal-active.js-modal-windows .header-logo,
  .js-modal-active.js-modal-windows .js-modal {
    padding-right: 15px; }

.js-modal {
  pointer-events: none;
  opacity: 0;
  z-index: 9;
  display: block;
  position: fixed;
  padding-top: 30px;
  padding-bottom: 30px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  overflow: auto;
  /* Enable scroll if needed */
  transition: opacity 0.1s ease-in; }
  @media (min-width: 680px) {
    .js-modal {
      padding-top: 50px; } }
  .js-modal.active {
    opacity: 1;
    pointer-events: unset; }

.modal {
  display: block;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 30px);
  top: 40px;
  transition: top 0.4s ease-in-out;
  text-align: center;
  font-size: 1.8rem;
  max-width: 800px;
  overflow: hidden;
  background: #000; }
  @media (min-width: 680px) {
    .modal {
      width: 90%;
      padding: 25px; } }
  .modal p {
    color: #FFF;
    margin: 0 auto 3rem; }
    .modal p.h3 {
      font-size: 3.9rem;
      margin: 0 0 2rem; }
      @media (min-width: 680px) {
        .modal p.h3 {
          font-size: 5rem; } }
    .modal p.h4 {
      font-size: 4rem; }
    .modal p.h3, .modal p.h4 {
      line-height: .94;
      letter-spacing: -.08em;
      font-family: 'Futura Condensed', sans-serif; }
    @media (max-width: 679.98px) {
      .modal p.not-heading {
        max-width: 99%; } }
  .modal .background-image-container {
    background: #000;
    z-index: -1; }
  @media (min-width: 810px) {
    .modal {
      max-width: 670px;
      padding: 50px;
      font-size: 2rem; } }
  .js-modal.active .modal {
    top: 0px; }
  .modal .btn {
    max-width: 100%; }
    @media (max-width: 679.98px) {
      .modal .btn[type="submit"] {
        font-size: 4rem; } }
  .modal .btn-disclaimer {
    margin: 15px auto 0;
    font-size: 1.5rem;
    max-width: 450px; }
  .modal .close-x {
    float: right;
    display: block;
    cursor: pointer;
    fill: #FFFFFF;
    position: relative;
    height: 14px;
    width: 14px;
    top: -10px;
    right: -10px; }
    @media (min-width: 680px) {
      .modal .close-x {
        top: -15px; } }
    @media (min-width: 810px) {
      .modal .close-x {
        height: 19px;
        width: 19px;
        top: -29px;
        right: -23px; } }

.loading-meter {
  height: 30px;
  position: relative;
  margin-bottom: 30px;
  background: #555;
  border-radius: 25px;
  padding: 6px;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  width: 85%;
  margin-left: auto;
  margin-right: auto; }
  .loading-meter > span {
    text-align: center;
    color: #fff;
    line-height: 24px;
    display: block;
    height: 100%;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: #D94632;
    position: relative;
    overflow: hidden; }

.loading-meter > span:after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0.05) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0.05) 75%, transparent 75%, transparent);
  z-index: 1;
  background-size: 50px 50px;
  animation: move 2s linear infinite;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden; }

@keyframes move {
  0% {
    background-position: 0 0; }
  100% {
    background-position: 50px 50px; } }

.exitintent {
  background: rgba(40, 39, 40, 0.8);
  backdrop-filter: blur(40px);
  padding: 40px 0; }
  @media (min-width: 1200px) {
    .exitintent {
      padding: 60px 0; } }
  @media screen and (min-width: 1200px) and (max-height: 1000px) {
    .exitintent {
      padding: 40px 0; } }

@-moz-document url-prefix() {
  .exitintent {
    background: rgba(40, 39, 40, 0.8); } }
  .exitintent .modal {
    background: #d1d1d1; }
    @media (min-width: 810px) {
      .exitintent .modal {
        padding: 35px 30px 45px;
        max-width: 760px; } }
    @media screen and (min-width: 1800px) {
      .exitintent .modal {
        max-width: 930px; } }
    @media screen and (min-width: 1200px) and (max-height: 1000px) {
      .exitintent .modal {
        padding: 35px 30px 45px;
        max-width: 760px; } }
    .exitintent .modal .close-x {
      fill: #9e9c9c;
      width: 15px;
      height: 15px;
      top: -18px;
      right: -3px; }
  .exitintent .content p:not(.h2) {
    line-height: 1.2;
    color: #343434;
    margin: 0 auto;
    letter-spacing: -.04em;
    max-width: 630px;
    font-size: 2.2rem; }
    @media (min-width: 810px) {
      .exitintent .content p:not(.h2) {
        font-size: 2.5rem; } }
    @media screen and (min-width: 1800px) {
      .exitintent .content p:not(.h2) {
        font-size: 3rem;
        max-width: 750px; } }
    @media screen and (min-width: 1200px) and (max-height: 1000px) {
      .exitintent .content p:not(.h2) {
        font-size: 2.2rem; } }
  .exitintent .content .h2 {
    line-height: 1;
    color: #000000;
    letter-spacing: -.025em;
    font-family: 'BarlowSemiCondensed', sans-serif;
    margin: 0 auto 2rem;
    font-size: 5rem; }
    @media (min-width: 680px) {
      .exitintent .content .h2 {
        font-size: 6rem; } }
    @media (min-width: 810px) {
      .exitintent .content .h2 {
        font-size: 8rem; } }
    @media screen and (min-width: 1800px) {
      .exitintent .content .h2 {
        font-size: 10rem; } }
    @media screen and (min-width: 1200px) and (max-height: 1000px) {
      .exitintent .content .h2 {
        font-size: 7rem; } }
  .exitintent .content .gif {
    max-width: 552px;
    margin: 3rem auto; }
    @media screen and (min-width: 1800px) {
      .exitintent .content .gif {
        max-width: 720px;
        margin: 3.5rem auto; } }
    @media screen and (min-width: 1200px) and (max-height: 1000px) {
      .exitintent .content .gif {
        max-width: 500px; } }
  .exitintent .content button {
    width: 100%;
    max-width: 550px;
    font-family: 'BarlowSemiCondensed', sans-serif;
    font-weight: 400;
    font-size: 3.6rem; }
    @media (min-width: 680px) {
      .exitintent .content button {
        font-size: 4.8rem; } }
    @media (min-width: 992px) {
      .exitintent .content button {
        max-width: 570px;
        font-size: 4.8rem; } }
    @media screen and (min-width: 1800px) {
      .exitintent .content button {
        max-width: 730px;
        font-size: 6rem; } }
    @media screen and (min-width: 1200px) and (max-height: 1000px) {
      .exitintent .content button {
        max-width: 570px;
        font-size: 4.8rem; } }
    .exitintent .content button:hover, .exitintent .content button:focus, .exitintent .content button:active {
      color: #FFF; }
  .exitintent__loading, .exitintent__graphic {
    width: 100%;
    margin: 0 auto; }
  .exitintent__loading {
    margin: 0 auto 1rem; }
  .exitintent__graphic {
    max-width: 1075px;
    margin: 2rem auto 1.5rem; }
    @media (min-width: 810px) {
      .exitintent__graphic {
        margin: 3.5rem auto 0rem; } }
  .exitintent .c-progress {
    position: relative;
    width: 90%;
    height: 21px;
    background: #9f9a9a;
    border-radius: 100px;
    overflow: hidden;
    border: 2px solid #a09b9b;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 3px 3px 5px rgba(255, 255, 255, 0.1);
    margin-bottom: 15px; }
  .exitintent .c-progress__bar.c-loading {
    animation-name: loading;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear; }
  .exitintent .c-progress__bar {
    transform: skewX(-40deg);
    transform-origin: bottom;
    padding-right: 45%;
    margin-left: -30px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(90deg, #ff2915 0%, #ff2915 33%);
    background-clip: content-box;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 100px; }
  @media screen and (min-width: 768px) {
    .exitintent .c-progress {
      width: 100%;
      max-width: 375px; } }
  @media screen and (min-width: 1800px) {
    .exitintent .c-progress {
      height: 28px;
      max-width: 480px;
      border: 4px solid #a09b9b;
      border-left-width: 5px; } }
  @media screen and (min-width: 1200px) and (max-height: 1000px) {
    .exitintent .c-progress {
      width: 100%;
      height: 21px;
      max-width: 375px; } }

@keyframes loading {
  from {
    padding-right: 45%; }
  to {
    padding-right: 5%; } }

.iti {
  text-align: left; }
  .iti .iti__selected-flag {
    padding: 0 15px 0 20px; }
  .iti.iti--separate-dial-code .iti__selected-flag {
    border-radius: 5px;
    padding-left: 25px; }
  .iti__flag-container {
    padding: 0;
    z-index: 5;
    background: #FFF;
    border-radius: 5px; }
  .iti > input[type="tel"] {
    padding-left: 75px; }

.email-gate {
  display: none; }

body.email-gate__open:not(.expired) {
  overflow: hidden; }
  body.email-gate__open:not(.expired) .email-gate {
    display: block;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(40px);
    padding-left: 10px;
    padding-right: 10px; }
    body.email-gate__open:not(.expired) .email-gate h3, body.email-gate__open:not(.expired) .email-gate .h3 {
      margin-bottom: 20px;
      max-width: 470px;
      margin-left: auto;
      margin-right: auto;
      font-size: 4rem; }
      @media (max-width: 809.98px) {
        body.email-gate__open:not(.expired) .email-gate h3, body.email-gate__open:not(.expired) .email-gate .h3 {
          font-size: 4rem; } }
    body.email-gate__open:not(.expired) .email-gate form {
      background: rgba(0, 0, 0, 0.3);
      border-radius: 15px;
      margin: 20vh auto 0;
      max-width: 640px;
      padding: 30px;
      text-align: center;
      width: 100%; }
      body.email-gate__open:not(.expired) .email-gate form input,
      body.email-gate__open:not(.expired) .email-gate form button {
        max-width: 430px;
        width: 100%;
        margin-left: auto;
        margin-right: auto; }

.section-hero {
  padding: 3rem 0 6rem;
  text-align: center;
  background: #D94632; }
  @media (min-width: 400px) {
    .section-hero {
      padding: 3.5rem 0 6rem; } }
  @media (min-width: 810px) {
    .section-hero {
      padding: 3rem 0 6.5rem; } }
  .section-hero__logo {
    max-width: 100px;
    margin: 0 auto; }
    .section-hero__logo img {
      width: 100%; }
  .section-hero__countdown {
    margin: 0 auto 2rem; }
    .section-hero__countdown .summit-countdown {
      line-height: 1.1; }
    @media (min-width: 400px) {
      .section-hero__countdown {
        margin: 0 auto 2.5rem; } }
    @media (min-width: 810px) {
      .section-hero__countdown {
        margin: 0 auto 4rem; } }
    @media (min-width: 1350px) {
      .section-hero__countdown {
        margin: 0 auto 7rem; } }
    @media screen and (min-width: 1400px) and (max-height: 1000px) {
      .section-hero__countdown {
        margin: 0 auto 4rem; } }
  .section-hero__video {
    position: relative;
    margin: 2rem auto 2.5rem; }
    @media (min-width: 400px) {
      .section-hero__video {
        margin: 3.5rem auto 2.8rem; } }
    .section-hero__video .background-image-container img {
      object-fit: contain; }
  .section-hero h1, .section-hero .h1 {
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    margin-bottom: 2rem; }
    @media (min-width: 992px) {
      .section-hero h1, .section-hero .h1 {
        margin-bottom: 4.5rem; } }
    @media (min-width: 810px) {
      .section-hero h1.quotes:before, .section-hero .quotes.h1:before, .section-hero h1.quotes:after, .section-hero .quotes.h1:after {
        font-size: 75%; }
      .section-hero h1.quotes:after, .section-hero .quotes.h1:after {
        top: -25px;
        right: -7px; } }
    @media (min-width: 1200px) {
      .section-hero h1.quotes:after, .section-hero .quotes.h1:after {
        top: -40px;
        right: -7px; } }
  .section-hero__wistia {
    max-width: 80%;
    margin: 0 auto; }
    .section-hero__wistia .wistia_embed {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3); }
    .section-hero__wistia .w-video-wrapper {
      background: none !important; }
    .section-hero__wistia video {
      object-fit: cover !important; }
    @media (min-width: 1350px) {
      .section-hero__wistia {
        max-width: 990px; } }
  .section-hero .btn {
    max-width: 765px;
    color: #FFF;
    background: #000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }
    .section-hero .btn i, .section-hero .btn span {
      color: #D2D2D2; }
    .section-hero .btn:hover, .section-hero .btn:focus, .section-hero .btn:active {
      color: #FFF;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
      background: #000; }
  .section-hero .ribbon {
    display: inline-block;
    left: 15px; }
    @media (min-width: 992px) {
      .section-hero .ribbon {
        left: 43px; } }
  .section-hero .eyebrow {
    margin: 0 0 1.2rem; }
  .section-hero .eyebrow {
    font-style: normal;
    font-weight: 400;
    line-height: 1.1;
    font-size: 2rem; }
    @media (max-width: 679.98px) {
      .section-hero .eyebrow {
        letter-spacing: initial;
        font-size: 1.8rem; } }
    @media screen and (max-width: 410px) {
      .section-hero .eyebrow {
        font-size: 1.6rem; } }
    .section-hero .eyebrow__flashing {
      background: rgba(0, 0, 0, 0.8);
      border-radius: 50px;
      padding: 5px 17px 4px;
      margin: 0 auto 2rem;
      display: table; }
      @media screen and (max-width: 410px) {
        .section-hero .eyebrow__flashing {
          padding: 5px 4px 4px 8px;
          width: 100%;
          display: block;
          text-align: center; } }
      @media (min-width: 680px) {
        .section-hero .eyebrow__flashing {
          padding: 5px 20px; } }
      @media (min-width: 810px) {
        .section-hero .eyebrow__flashing {
          padding: 7px 40px 4px 30px; } }
      @media (min-width: 992px) {
        .section-hero .eyebrow__flashing {
          padding: 9px 30px 7px 30px; } }
      @media (min-width: 1200px) {
        .section-hero .eyebrow__flashing {
          padding: 9px 30px 7px 30px; } }
    .section-hero .eyebrow__flashing-dot {
      background-color: #D94632;
      content: " ";
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 5px;
      margin-left: -10px;
      top: -1px;
      position: relative;
      vertical-align: middle;
      display: inline-block;
      flex: none; }
      @media (min-width: 810px) {
        .section-hero .eyebrow__flashing-dot {
          top: -2px;
          width: 28px;
          height: 28px;
          display: inline-block;
          margin-right: 10px;
          margin-left: -20px; } }
      @media screen and (min-width: 992px) and (max-width: 1139px) {
        .section-hero .eyebrow__flashing-dot {
          top: -2px; } }
      .section-hero .eyebrow__flashing-dot + span {
        margin-right: 6px; }
      .section-hero .eyebrow__flashing-dot:before {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        top: 0;
        left: 0;
        border-radius: 50%;
        background: #D94632;
        box-sizing: border-box;
        z-index: 1;
        animation: smallPulse 2s ease-out infinite; }
        @media (min-width: 810px) {
          .section-hero .eyebrow__flashing-dot:before {
            width: 28px;
            height: 28px; } }
    .section-hero .eyebrow__blinking {
      -webkit-animation: blinkingText 2.2s infinite;
      animation: blinkingText 2.2s infinite;
      -webkit-transition: opacity 0.2s ease;
      transition: opacity 0.2s ease;
      color: red;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 1.2rem;
      letter-spacing: 0.056em;
      vertical-align: middle;
      margin-bottom: 8px;
      background: none; }
      .section-hero .eyebrow__blinking:before {
        content: " ";
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: red;
        margin-right: 8px; }
      @media (min-width: 768px) {
        .section-hero .eyebrow__blinking {
          font-size: 1.7rem;
          margin-bottom: 20px; }
          .section-hero .eyebrow__blinking:before {
            width: 12px;
            height: 12px; } }

@-webkit-keyframes blinkingText {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes blinkingText {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  to {
    opacity: 1; } }
  .section-hero__cash {
    width: 1600px;
    max-width: 100vw;
    position: absolute;
    left: 49%;
    top: 54%;
    transform: translate(-50%, -50%); }
    @media (min-width: 1200px) {
      .section-hero__cash {
        left: calc(50% - 13px);
        max-width: 97vw; } }
  .section-hero__seenin {
    max-width: 815px;
    margin: 6rem auto 0; }
    @media (min-width: 810px) {
      .section-hero__seenin {
        margin: 6rem auto 2rem; } }

.section-marquee {
  padding: 0px 0 0;
  background: none; }
  @media (min-width: 810px) {
    .section-marquee {
      padding: 40px 0 0; } }
  .section-marquee .marquee-row {
    margin: 0 0 10px; }
    @media (min-width: 810px) {
      .section-marquee .marquee-row {
        margin: 0 0 20px; } }
    .section-marquee .marquee-row:nth-child(1) {
      animation: cssmarquee1 110s linear infinite; }
    .section-marquee .marquee-row:nth-child(2) {
      animation: cssmarquee2 110s linear infinite; }
    .section-marquee .marquee-row:nth-child(3) {
      animation: cssmarquee1 150s linear infinite; }
    @media (min-width: 810px) {
      .section-marquee .marquee-row:nth-child(1) {
        animation: cssmarquee1 190s linear infinite; }
      .section-marquee .marquee-row:nth-child(2) {
        animation: cssmarquee2 190s linear infinite; }
      .section-marquee .marquee-row:nth-child(3) {
        animation: cssmarquee1 230s linear infinite; } }
    .section-marquee .marquee-row__scroller {
      display: flex;
      flex-wrap: nowrap; }

@keyframes cssmarquee1 {
  0% {
    transform: translateX(-220%); }
  100% {
    transform: translateX(-2%); } }

@keyframes cssmarquee2 {
  0% {
    transform: translateX(-2%); }
  100% {
    transform: translateX(-200%); } }
      .section-marquee .marquee-row__scroller div {
        font-family: "PodiumSharp211", sans-serif;
        font-style: italic;
        font-weight: 400;
        font-size: 4rem;
        padding: 6px 15px;
        line-height: 1;
        text-transform: uppercase;
        color: #FFFFFF;
        position: relative;
        display: block;
        white-space: nowrap;
        margin: 0 5px; }
        @media (min-width: 810px) {
          .section-marquee .marquee-row__scroller div {
            font-size: 6rem;
            padding: 8px 20px;
            margin: 0 9px; } }
        .section-marquee .marquee-row__scroller div span {
          position: relative;
          z-index: 1; }
        .section-marquee .marquee-row__scroller div:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #D94632;
          transform: skewY(-7deg) rotate(7deg);
          z-index: 0; }
        .section-marquee .marquee-row__scroller div i {
          top: -2px;
          line-height: .8; }

.section-copy.bg-dark {
  color: #FFF; }

.section-copy .h3 {
  font-style: italic; }

.section-copy h2 + .lead, .section-copy .h2 + .lead {
  margin-top: 4rem;
  margin-bottom: 4rem; }
  @media (min-width: 1200px) {
    .section-copy h2 + .lead, .section-copy .h2 + .lead {
      margin-top: 5rem;
      margin-bottom: 5rem; } }

.section-copy .signature {
  width: 200px;
  max-width: 100%;
  margin: 0 0 4rem; }
  @media (min-width: 992px) {
    .section-copy .signature {
      width: 300px; } }

.section-copy .img-fluid {
  margin: 0 auto 1.5rem; }

.section-copy .btn-section {
  margin: 5rem auto 0; }
  @media (min-width: 810px) {
    .section-copy .btn-section {
      margin: 7rem auto 0; } }

.section-copy .btn-signature {
  margin: 4.5rem auto; }
  @media (min-width: 992px) {
    .section-copy .btn-signature {
      margin: 4.5rem auto 10rem; } }

@media (max-width: 991.98px) {
  .section-copy--attend h2, .section-copy--attend .h2 {
    margin: 0 0 2rem; }
  .section-copy--attend ul {
    margin-top: 1.5rem; } }

.section-copy--register h2, .section-copy--register .h2 {
  margin: 0; }

@media (min-width: 992px) {
  .section-copy--register img.img-fluid {
    margin: -10px auto; } }

@media (min-width: 992px) {
  .section-copy--bonus img.img-fluid {
    margin: -10px auto 40px; } }

@media (max-width: 809.98px) {
  .section-copy--bonus h2, .section-copy--bonus .h2 {
    margin-bottom: 3rem; }
  .section-copy--bonus img.img-fluid {
    margin: 0 auto 3rem; } }

.section-featuredin h4, .section-featuredin .h4 {
  font-family: "PodiumSharp212", sans-serif;
  font-style: italic;
  font-size: 23px;
  line-height: 1.3;
  text-align: center;
  letter-spacing: 0.1em;
  color: #C9C9C9;
  margin-bottom: 5rem; }
  @media (max-width: 679.98px) {
    .section-featuredin h4, .section-featuredin .h4 {
      margin-bottom: 3rem; } }

.section-featuredin img {
  max-width: 1114px;
  margin: 0 auto;
  display: block; }

body.thank-you #hero {
  min-height: 95vh; }

.free-swipe-file form {
  background: rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 30px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto; }

.free-swipe-file h3, .free-swipe-file .h3 {
  line-height: 1.1;
  margin-bottom: 20px; }

.free-swipe-file input,
.free-swipe-file button {
  max-width: 90%; }

.free-swipe-file button {
  cursor: pointer; }

.expired .section-hero {
  min-height: 100vh; }

.expired h2, .expired .h2 {
  color: #FFF;
  font-size: 8rem;
  max-width: 700px;
  margin: 0 auto 3rem;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.2); }
  @media (min-width: 992px) {
    .expired h2, .expired .h2 {
      font-size: 11rem; } }

.expired p {
  color: #FFF;
  margin: 0 0 3rem;
  font-size: 2.2rem; }

.expired .thankyou {
  display: none; }

.expired form {
  max-width: 500px;
  margin: 4rem auto 0; }
  @media (min-width: 992px) {
    .expired form {
      margin: 5rem auto 0; } }

.expired:not(.email-gate__open) .thankyou {
  display: block; }

.expired:not(.email-gate__open) .not-thankyou {
  display: none; }

/* purgecss end ignore */
