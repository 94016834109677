// --------------------
// Include Fonts Here
// --------------------
@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Regular.woff2') format('woff2'),
    url('../fonts/Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Bold.woff2') format('woff2'),
    url('../fonts/Inter-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-BoldItalic.woff2') format('woff2'),
    url('../fonts/Inter-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Black.woff2') format('woff2'),
    url('../fonts/Inter-Black.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'PodiumSharp211';
    src: url('../fonts/PODIUMSharp-211.woff2') format('woff2'),
    url('../fonts/PODIUMSharp-211.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PodiumSharp211';
    src: url('../fonts/PodiumSharp211Italic.woff2') format('woff2'),
    url('../fonts/PodiumSharp211Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'PodiumSharp212';
    src: url('../fonts/PodiumSharp212Regular.woff2') format('woff2'),
    url('../fonts/PodiumSharp212Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PodiumSharp212';
    src: url('../fonts/PodiumSharp212Italic.woff2') format('woff2'),
    url('../fonts/PodiumSharp212Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'BarlowCondensed';
    src: url('../fonts/BarlowCondensed-ExtraBold.woff2') format('woff2'),
    url('../fonts/BarlowCondensed-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'BarlowSemiCondensed';
    src: url('../fonts/BarlowSemiCondensed-Black.woff2') format('woff2'),
    url('../fonts/BarlowSemiCondensed-Black.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Futura';
    src: url('../fonts/Futura-Medium-Italic.woff2') format('woff2'),
    url('../fonts/Futura-Medium-Italic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Futura Condensed';
    src: url('../fonts/Futura-Condensed-Extra-Bold.woff2') format('woff2'),
    url('../fonts/Futura-Condensed-Extra-Bold.woff') format('woff');
    font-weight: 400;
}

$headings-font: 'PodiumSharp211', sans-serif;
$headings-font-2: 'PodiumSharp212', sans-serif;
$body-font: 'Inter', sans-serif;

// --------------------
//  Include Basic Typography
// --------------------

:root {
    font-size: 50%;  //1rem = 8px
    @include media-breakpoint-up(md) {
        font-size: 56.25%; //1rem = 9px
    }
    @include media-breakpoint-up(lg) {
        font-size: 62.5%; // 1rem = 10px
    }
}

html, body {
    @include font-smoothing('on');
    font-size: 2.5rem;
    line-height: 1.5;
    font-family: $body-font;

    @include media-breakpoint-up(xl){
        font-size: 2.9rem;
    }
}

p {
    margin-bottom: 3rem;

    &:last-child{
        margin-bottom: 0;
    }

    @include media-breakpoint-up(md){
        margin-bottom: 4rem;
    }

    em{
        font-style: normal;
        font-weight: 400;
        background: $yellow;
        padding: 0px 4px;
    }

    &.letterhead{
        font-size: 16px;

        &:after{
            content:'';
            width: 130px;
            height: 6px;
            background: #000000;
            display: block;
            margin: 4rem 0;

            @include media-breakpoint-up(md){
                margin: 4.5rem 0;
                height: 8px;
            }
        }
    }

    &.lead{
        font-weight: 700;
        font-size: 3rem;
        line-height: 1.3;

        @include media-breakpoint-up(xl){
            font-size: 3.7rem;
        }
    }

    a{
        transition: color 300ms ease;

        &:hover{
            color: $red1;
        }
    }
}

.font-normal {
     font-style: normal !important;
}

// Headline padding
h1, .h1, 
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    line-height: 1.3;
    margin-bottom: 4.5rem;
    font-family: $headings-font;
    font-weight: normal;
    text-transform: uppercase;
}

h1, .h1{
    font-size: 12.6vw;
}

h2, .h2{
    font-size: 13vw;
}

h1, .h1, h2, .h2{
    line-height: .86;
    letter-spacing: -0.01em;
    text-transform: uppercase;

    .h3{
        margin-bottom: 1rem;
        display: block;
    }

    &.bigonmobile{
        @include media-breakpoint-down(smx){
            font-size: 14.5vw;
        }
    }

    @include media-breakpoint-up(sm){
        font-size: 10vw;
    }

    @include media-breakpoint-up(md){
        font-size: 10vw;
    }

    @include media-breakpoint-up(lg){
        font-size: 10vw;
    }

    @include media-breakpoint-up(xl){
        font-size: 11vw;
    }

    @media screen and (min-width: 1400px){
        font-size: 16.1rem;
    }
    
    @media screen and (min-width: 1400px) and (max-height: 1000px){
        font-size: 13rem;
    }
}


h3, .h3 {
    font-size: 3rem;  
    letter-spacing: 0;
    font-family: $headings-font-2;

    @include media-breakpoint-up(sm){
        font-size: 3.5rem;  
    }

    @include media-breakpoint-up(xl){
        font-size: 4.24rem;  
    }
}

h4, .h4 {
    font-size: 2.4rem;  //Laptop: 24px, Ipads: 21.6px, Mobiles: 19.2px
}

h5, .h5 {
    font-size: 2rem;  //Laptop: 20px, Ipads: 18px, Mobiles: 16px
}

h6, .h6 {
    font-size: 1.8rem;  //Laptop: 18px, Ipads: 16.2px, Mobiles: 14.4px
}

// Hero Specific Font Sizes
.eyebrow {
    text-transform: uppercase;
    font-family: $body-font;
    font-style: italic;
    font-weight: 700;
    font-size: 2rem;
    line-height: .95;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFF;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 400px){
        font-size: 1.65rem;
        letter-spacing: 0.01em;
    }

    @include media-breakpoint-up(md){
        font-size: 2.4rem;
    }
}

.subheadline {
    font-size: 2.2rem;
}

.quotes{
    &:before,
    &:after{
        font-family: $body-font;
        font-weight: 800;
        font-size: 80%;
        vertical-align: top;
        line-height: 1;
        position: relative;
        top: 0px;
    }
    &:before{
        content:'“';
        left: 5px;
    }
    &:after{
        content:'”';
        right: 0px;
    }

    @include media-breakpoint-up(lg){
        &:before,
        &:after{
            top: -5px;
        }
        
        &:before{
            content:'“';
            left: 10px;
        }
        &:after{
            content:'”';
            right: -10px;
        }
    }

    @include media-breakpoint-up(xl){
        &:before,
        &:after{
            top: -10px;
        }
        
        &:before{
            content:'“';
            left: 20px;
        }
        &:after{
            content:'”';
            right: 0px;
        }
    }

    &--alt{
        font-style: normal;

        &:before,
        &:after{
            left: 0;
            content: '"';
            font-family: 'Futura Condensed', sans-serif;
            top: 5px;
        }
    }
}

.ssb{
    font-family: 'Futura', sans-serif;
    position: relative;

    &--inter{
        font-family: $body-font;

        h2 &{
            line-height: .8;
            display: inline-block;
            position: relative;
            left: -9px;
            top: -8px;
        }
    }

    &--cond{
        font-style: normal;
        font-family: 'Futura Condensed', sans-serif;
    }

    &--i{
        font-style: italic;
    }

    &--m{
        font-weight: 600;
    }
}

i {
    font-style: normal;
}

.summit-countdown {
    font-size: 2rem;
    font-weight: 600;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center; 

    i {
        font-style: normal;
        display: block;

        @include media-breakpoint-up(sm) {
            display: inline;
        }
    }
}