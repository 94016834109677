
// --------------------
// GLOBAL COMPONENTS
//---------------------

// Generic Section Padding - adds padding to each section - most of your sections should use this - override for special cases
section {
    padding: 60px 0px;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(md) {
        padding: 80px 0px;
    }
    @include media-breakpoint-up(lg) {
        padding: 120px 0px;
    }

    .container{
        z-index: 1;
        position: relative;
    }
}

// Background Images - positions the background image behind the content in the section
.background-image-container {
    overflow: hidden;
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &.mh-1{
        max-height: 87vw;
    }

    &.mh-2{
        top: auto;
        bottom: 0;
        max-height: 87vw;
    }

	img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
    }
    
    // Background Overlay - Adds a colour overlay with transparency options
    &.semi-transparent-bg {
        &-dark {
            background: #000;
            img {
                opacity: 0.6;
            }
        }
        &-light {
            background: #fff;
            img {
                opacity: 0.4;
            }
        }
    }
}

// Youtube Video Embeds
.video-preview-container {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    
    iframe,
    object,
    embed,
    img.poster,
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

	.play-button {
        width: 100px;
		position: absolute;
		left: calc(50% - 50px);
		top: calc(50% - 40px);
		padding: 0;
		margin: 0;
		border: none;
		appearance: none;
		background: none;
		z-index: 5;
		transition: .8s ease;

		&:hover {
			transform: scale(1.1);
			cursor: pointer;
		}
		&:focus {
			outline: 0;
		}
	}
}

img {
    width: 100%;
}
.max-width-image {
    width: 100%;
    max-width: 300px;
    @include media-breakpoint-up(md) {
        max-width: 400px;
    }
    @include media-breakpoint-up(lg) {
        max-width: 100%;
    }
}

.bg-dark{
    background: linear-gradient(180deg, #232323 0%, #000000 100%);
}

.color {
    &-primary {
        color: $primary;
    }
    &-secondary {
        color: $secondary;
	}
}

.grecaptcha-badge { visibility: hidden; }